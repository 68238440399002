import OnlineTestUtil from '@/components/util/OnlineTestUtil';
import native from '@/api/native';
import { HTTP } from './http-common';
import discovery from '../discovery';

export default {
	getLocalOnlineTests() {
		const values = [];
		const keys = Object.keys(localStorage);

		for (let i = 0; i < keys.length; i += 1) {
			const key = keys[i];
			if (key.indexOf('evs-ot-EV-') === 0) {
				values.push(native.getItem(key));
			}
		}

		return values;
	},
	getLocalOnlineTest(onlineTestId) {
		return native.getItem(`evs-ot-${onlineTestId}`);
	},
	setLocalOnlineTest(onlineTestId, xmlTDef) {
		native.setItem(`evs-ot-${onlineTestId}`, xmlTDef);
	},
	removeLocalOnlineTest(onlineTestId) {
		const keys = Object.keys(localStorage);

		for (let i = 0; i < keys.length; i += 1) {
			const key = keys[i];
			if (key.indexOf(`evs-ot-img-${onlineTestId}`) === 0) {
				native.removeItem(key);
			}
		}

		native.removeItem(`evs-ot-pwd-${onlineTestId}`);

		native.removeItem(`evs-ot-${onlineTestId}`);
	},
	getLocalOnlineTestPassword(onlineTestId) {
		const password = native.getItem(`evs-ot-pwd-${onlineTestId}`);
		return password ? atob(password) : null;
	},
	removeOnlineTestImagesLocally() {
		const keys = Object.keys(localStorage);

		for (let i = 0; i < keys.length; i += 1) {
			const key = keys[i];
			if (key.indexOf(`evs-ot-img-${window.$store.state.onlineTestId}`) === 0) {
				native.removeItem(key);
			}
		}
	},
	saveOnlineTestLocally(password) {
		const xmlTDef = OnlineTestUtil.getCurrentTDefAsXML();
		native.setItem(`evs-ot-${window.$store.state.onlineTestId}`, xmlTDef);
		if (password) {
			native.setItem(`evs-ot-pwd-${window.$store.state.onlineTestId}`, btoa(password));
		}
	},
	getOnlineTest(onlineTestId, studentId, deviceId, cbok, cberr) {
		const servletUrl = `${discovery.online_test_endpoint}GetHomeworkTestServlet?action=getHomework&client=EV`;

		const hwXml = OnlineTestUtil.getHomeworkXML(onlineTestId, studentId, deviceId);

		const formData = new FormData();
		formData.append('GetHomework', hwXml);

		HTTP.post(servletUrl, formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					'Hmac-request': OnlineTestUtil.getHmac(hwXml),
				}
			}).then((response) => {
			response.data = response.data.replaceAll('&gt;, ,', '&gt;').replaceAll('&gt;,', '&gt;');
			cbok(response.data);
		}).catch((error) => {
			cberr(error);
		});
	},
	getOnlineTestProtected(onlineTestId, studentId, password, cbok, cberr) {
		const servletUrl = `${discovery.online_test_endpoint}GetHomeworkTestServlet?action=VerifyPassword&client=EV`;

		const hwXml = OnlineTestUtil.getHomeworkXMLProtected(onlineTestId, studentId, password);

		const formData = new FormData();
		formData.append('GetHomework', hwXml);

		HTTP.post(servletUrl, formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					'Hmac-request': OnlineTestUtil.getHmac(hwXml),
				}
			}).then((response) => {
			response.data = response.data.replaceAll('&gt;, ,', '&gt;').replaceAll('&gt;,', '&gt;');
			cbok(response.data);
		}).catch((error) => {
			cberr(error);
		});
	},
	submitOnlineTest(onlineTestId, studentId, deviceId, cbok, cberr) {
		const servletUrl = `${discovery.online_test_endpoint}PostResponsesServlet?action=postResponses&client=EV`;

		const hwXml = OnlineTestUtil.setHomeworkXML(onlineTestId, studentId, deviceId);

		const formData = new FormData();
		formData.append('PostResponses', hwXml);

		HTTP.post(servletUrl, formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					'Hmac-request': OnlineTestUtil.getHmac(hwXml),
				}
			}).then((response) => {
			response.data = response.data.replaceAll('&gt;, ,', '&gt;').replaceAll('&gt;,', '&gt;');
			cbok(response.data);
		}).catch((error) => {
			cberr(error);
		});
	},
};
