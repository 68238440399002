import Vue from 'vue';
import a2ac from './app2appCordova';

let eventBus = null;

/* eslint-disable no-unused-vars */
export default {
	init(nativeEventBus) {
		this.setEventBus(nativeEventBus);
		if (window.cordova.platformId === 'android') {
			document.addEventListener('backbutton', (event) => {
				event.preventDefault();
				this.getEventBus().$emit('back-button');
			}, false);
		}

		document.addEventListener('resume', (event) => {
			setTimeout(() => {
				this.getEventBus().$emit('window-focus');
			}, 100);
		}, false);

		document.addEventListener('pause', (event) => {
			setTimeout(() => {
				this.getEventBus().$emit('window-blur');
			}, 100);
		}, false);

		a2ac.setMessageCallback((message) => {
			this.getEventBus().$emit('a2a-message-received', message);
		});

		a2ac.setConnectionCallback((res) => {
			this.getEventBus().$emit('a2a-connection-event', res);
		});

		Vue.$log.info('Cordova platform initialized');
	},
	setEventBus(bus) {
		eventBus = bus;
	},
	getEventBus() {
		return eventBus;
	},
	getItem(key) {
		return localStorage.getItem(key);
	},
	setItem(key, value) {
		localStorage.setItem(key, value);
	},
	removeItem(key) {
		localStorage.removeItem(key);
	},
	exitApp() {
		navigator.app.exitApp();
	},
	// app2app local
	a2aConnect(host, port) {
		a2ac.connect(host, port);
	},
	a2aDisconnect() {
		a2ac.disconnect();
	},
	a2aSendMessage(message) {
		a2ac.sendMessage(message);
	},
	downloadFile(url, options) {
		return new Promise(((resolve, reject) => {
			window.cordova.plugin.http.get(url, {}, options, resolve, reject);
		}));
	}
};
