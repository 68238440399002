import Vue from 'vue';
import Vuex from 'vuex';
import { Mode } from '@/components/common/constants';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		helpUrl: null,
		version: null,
		deviceId: null,
		mode: Mode.NONE,
		studentId: null,
		sessionId: null,
		studentInfo: null,
		sessionInfo: null,
		currentQDef: null,
		currentTDef: null,
		currentQIndex: -1,
		smaMode: false,
		isSendingAnswer: false,
		onlineTestId: null,
		isOnlineTestSent: false,
		showDownloadInstallerCard: true,
	},
	mutations: {
		setHelpUrl(state, helpUrl) {
			state.helpUrl = helpUrl;
		},
		setVersion(state, version) {
			state.version = version;
		},
		setDeviceId(state, deviceId) {
			state.deviceId = deviceId;
		},
		setMode(state, mode) {
			state.mode = mode;
		},
		setStudentId(state, studentId) {
			state.studentId = studentId;
		},
		setSessionId(state, sessionId) {
			state.sessionId = sessionId;
		},
		setStudentInfo(state, studentInfo) {
			state.studentInfo = studentInfo;
		},
		setSessionInfo(state, sessionInfo) {
			state.sessionInfo = sessionInfo;
		},
		setCurrentQDef(state, currentQDef) {
			state.currentQDef = currentQDef;
		},
		setCurrentAnswer(state, currentAnswer) {
			if (state.mode === Mode.POLLING_SESSION) {
				state.currentQDef.givenAnswer = currentAnswer;
			} else if (state.mode === Mode.ONLINE_TEST) {
				state.currentTDef.questions[state.currentQIndex].givenAnswer = currentAnswer;
			}
		},
		setCurrentTDef(state, currentTDef) {
			state.currentTDef = currentTDef;
		},
		setCurrentQIndex(state, currentQIndex) {
			if (currentQIndex < 0 || currentQIndex > state.currentTDef.questions.length - 1) {
				return;
			}
			state.currentQIndex = currentQIndex;
		},
		setSmaMode(state, smaMode) {
			state.smaMode = smaMode;
		},
		setIsSendingAnswer(state, isSendingAnswer) {
			state.isSendingAnswer = isSendingAnswer;
		},
		/* ONLIE TEST */
		setOnlineTestId(state, onlineTestId) {
			state.onlineTestId = onlineTestId;
		},
		setIsOnlineTestSent(state, isOnlineTestSent) {
			state.isOnlineTestSent = isOnlineTestSent;
		},
		setShowDownloadInstallerCard(state, showDownloadInstallerCard) {
			state.showDownloadInstallerCard = showDownloadInstallerCard;
		},
	},
	actions: {
	},
	modules: {
	},
});
