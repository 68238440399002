<template>
  <div class="about">
    <h1 class="m-3">App2App test</h1>
		<b-container class="mt-5">
			<b-row align-self="center">
				<b-col cols="4" md="2">
					<b-form-input v-model="studentId" placeholder="Student ID"></b-form-input>
				</b-col>
				<b-col cols="4" md="2">
					<b-form-input v-model="sessionId" placeholder="Session ID"></b-form-input>
				</b-col>
				<b-col cols="4">
					<b-button
						style="width: 100px;"
						variant="success"
						:disabled="!studentId || !sessionId || isConnected"
						@click="connect()">
							Connect
						</b-button>
					<b-button
						style="width: 100px;"
						class="ml-2"
						variant="success"
						:disabled="!isConnected || isSending"
						@click="post('DISCONNECT', '')">Disconnect</b-button>
				</b-col>
			</b-row>
			<b-row class="mt-2">
				<b-col cols="4" md="2">
					<b-form-input v-model="messageType" :disabled="!isConnected" placeholder="Message type"/>
				</b-col>
				<b-col cols="4" md="2">
					<b-form-input v-model="messageContent" :disabled="!isConnected" placeholder="Content"/>
				</b-col>
				<b-col cols="4">
					<b-button
						style="width: 100px;"
						variant="success"
						:disabled="!isConnected || isSending"
						@click="post(messageType, messageContent)">
						Send
					</b-button>
					<b-button
						style="width: 100px;"
						class="ml-2"
						variant="success"
						:disabled="!isConnected || isSending"
						@click="post('REFRESH', studentId)">
						Refresh
					</b-button>
				</b-col>
			</b-row>
		</b-container>
		<b-container class="mt-5 text-center">
			<b-row>
				<b-col cols="12" v-show="showWelcome">
					<h1>Welcome</h1>
				</b-col>
				<b-col cols="12">
					<span>{{ info }}</span>
				</b-col>
				<b-col cols="12" class="mt-4" v-show="showWelcome">
					<div v-html="studentInfo"/>
				</b-col>
			</b-row>
		</b-container>
		<b-container style="position: fixed; bottom: 5px; font-size: 10px;">
			<span>{{ $store.state.deviceId }}</span>
		</b-container>

		<b-modal id="passwordDialog" title="Session protected">
			<p class="my-4">This session is protected. Please enter the provided session password:</p>
			<b-form id="form" @submit.stop.prevent="checkPasswordSession()" required>
				<b-form-input id="passwordText" v-model="sessionPassword" autocomplete="off" required/>
			</b-form>
			<template #modal-footer>
				<b-btn @click="$bvModal.hide('passwordDialog')">Cancel</b-btn>
				<b-btn variant="primary" type="submit" form="form">OK</b-btn>
			</template>
		</b-modal>

		<b-alert variant="danger"
			class="m-4"
			:show="alertDismissCountDown">
			<h3 class="alert-heading">Session protected</h3>
			<p>Entered password is wrong, please check your session information and try again.</p>
		</b-alert>
  </div>
</template>

<script>
import a2aUtils from '@/model/A2AUtils';
import app2app from '../api/app2app';
import PollingSessionInfo from '../model/PollingSessionInfo';
import QuestionDefinition from '../model/QuestionDefinition';
import ProtectedSessionInfo from '../model/ProtectedSessionInfo';

export default {
	name: 'Test',
	data() {
		return {
			studentId: '1001',
			sessionId: null,
			// deviceId: '3123-A2E1',
			messageType: null,
			sessionInfo: null,
			protectedSessionInfo: null,
			messageContent: null,
			masterUserId: null,
			isConnected: false,
			isSending: false,
			showWelcome: false,
			studentInfo: null,
			info: null,
			sessionPassword: null,
			alertDismissCountDown: 0,
		};
	},
	mounted() {
		const self = this;
		app2app.getEventBus().$on('A2AConnectMessage', (message) => {
			self.showToast('A2AConnectMessage', message.message);
		});

		app2app.getEventBus().$on('A2AMessageInfo', (message) => {
			console.log(`A2AMessageInfo - ${message.sourceUserId} --> ${message.messageType} : ${message.messageContent}`);

			if (message.messageType === 'MASTER') {
				self.sessionInfo = new PollingSessionInfo(message.messageContent);
				self.masterUserId = message.sourceUserId;
				self.isConnected = true;
				self.post('JOINTABLET', self.studentId);
				return;
			}

			if (message.messageType === 'PMASTER2') {
				self.showToast('Session protected', message.messageContent);
				self.protectedSessionInfo = new ProtectedSessionInfo(message.messageContent);
				self.masterUserId = message.sourceUserId;
				// self.currentSessionPassword = message.messageContent;
				// self.currentSessionPassword = self.currentSessionPassword.replace('<pass>', '')
				// .replace('</pass><app-id>flow2</app-id>', '');
				self.sessionPassword = null;
				self.$bvModal.show('passwordDialog');
				return;
			}

			if (message.messageType === 'QDEF') {
				const qdef = new QuestionDefinition(message.messageContent);
				self.showToast('New question', `${qdef.questionId} --> ${qdef.questionType} : ${qdef.questionExternalContent}`);
				self.showWelcome = false;
				self.info = `${qdef.questionId} --> ${qdef.questionType}`;
				return;
			}

			if (message.messageType === 'WELCOME') {
				self.showToast('Welcome', a2aUtils.safeDecode(message.messageContent));
				self.studentInfo = a2aUtils.safeDecode(message.messageContent);
				self.displayWelcome();
				return;
			}

			if (message.messageType === 'JOINERROR') {
				self.showToast('JOINERROR', message.messageContent);
				this.disconnect();
			}

			if (message.messageType === 'SFINISHED') {
				self.showToast('SFINISHED', message.messageContent);
				this.disconnect();
			}

			if (message.messageType === 'MSG') {
				if (message.messageContent.trim().length === 0) {
					self.displayWelcome();
					return;
				}

				self.info = message.messageContent;
			}

			self.showToast('A2AMessageInfo', `${message.sourceUserId} --> ${message.messageType} : ${message.messageContent}`);
		});
	},
	methods: {
		post(type, content) {
			const umid = btoa(Math.random()).slice(0, 10); // generate random id
			this.isSending = true;
			app2app.postMessage(this.masterUserId, type, content, umid, (res) => {
				this.$log.debug(res);
				this.isSending = false;

				if (type === 'JOIN2' && res.rsok) {
					this.$log.debug(`connected: ${res.rsok}`);

					this.isConnected = true;
					localStorage.studentId = this.studentId.toUpperCase();
					localStorage.sessionId = this.sessionId;
				}

				if (type === 'DISCONNECT' && res.rsok) {
					this.disconnect();
				}
			}, (error) => {
				this.$log.error(error);
				this.isSending = false;
			});
		},
		showToast(title, message) {
			this.$bvToast.toast(message, {
				title,
				autoHideDelay: 5000,
				appendToast: true
			});
		},
		connect() {
			app2app.connect(`EVAS${this.sessionId}`, this.$store.state.deviceId, (res) => {
				this.$log.debug(`connected: ${res.rsok}`);

				localStorage.studentId = this.studentId.toUpperCase();
				localStorage.sessionId = this.sessionId;
			}, (error) => {
				console.log(error);
			});
		},
		displayWelcome() {
			this.showWelcome = true;
			this.info = 'Waiting for new questions...';
		},
		disconnect() {
			this.$log.debug('disconnected!');
			this.isConnected = false;
			this.showWelcome = false;
			this.info = null;
			this.messageType = null;
			this.messageContent = null;
			app2app.disconnect();
		},
		checkPasswordSession() {
			this.$bvModal.hide('passwordDialog');

			if (this.sessionPassword !== this.protectedSessionInfo.pass) {
				this.alertDismissCountDown = 6;
				return;
			}

			this.post('JOIN2', this.studentId);
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.studentId = localStorage.studentId;
			vm.sessionId = localStorage.sessionId;

			if (!vm.studentId) {
				vm.studentId = '1001';
			}
		});
	}
};
</script>
