import xml2js from 'xml2js';

export default class A2AConnectMessage {
	constructor(userInfo, sessionInfo) {
		const uinfo = userInfo;

		this.sinfo = { ...sessionInfo };
		delete this.sinfo.serialize;

		this.srid = sessionInfo.service;
		this.ssid = sessionInfo.siid;
		this.uid = uinfo.uid;
		this.ucm = uinfo.ucm;
		this.ut = uinfo.ut;
		this.acs = 'false';
		this.bkns = '';

		const builder = new xml2js.Builder({
			rootName: 'app2app',
			renderOpts: {
				pretty: false, indent: '', newline: '', cdata: true
			}
		});
		this.serialize = () => {
			const clone = { ...this };
			delete clone.serialize;
			clone.$ = { id: 1, type: 'connect', version: '2' };

			return builder.buildObject(clone);
		};
	}
}
