<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import native, { Platform, OS } from '@/api/native';
import discovery from '@/discovery';
import crypto from 'crypto';

export default {
	name: 'App',
	components: {
	},
	data() {
		return {
		};
	},
	mounted() {
		window.evas_removeLoadingContainer();

		window.setLogLevel(this.$route.query.logLevel);
		window.setLocale(this.$route.query.lang);

		native.init();

		this.getDeviceId();
		this.getHelpUrl();
		this.$store.commit('setVersion', discovery.version);
	},
	methods: {
		getDeviceId() {
			let deviceId = native.getItem('deviceId');

			if (deviceId && /^[0-9A-Z]{4}-[0-9A-Z]{4}$/.test(deviceId)) {
				this.$store.commit('setDeviceId', deviceId);
				return;
			}

			const hexString1 = crypto.randomBytes(2).toString('hex');
			const hexString2 = crypto.randomBytes(2).toString('hex');

			deviceId = `${hexString1.toUpperCase()}-${hexString2.toUpperCase()}`;

			native.setItem('deviceId', deviceId);
			this.$store.commit('setDeviceId', deviceId);
		},
		getHelpUrl() {
			let path = null;

			const os = native.getOS();

			switch (native.getPlatform()) {
			case Platform.ELECTRON:
				if (os === OS.WINDOWS) {
					path = 'PC';
				} else if (os === OS.MACOS) {
					path = 'Mac';
				}
				break;
			case Platform.CORDOVA:
				if (os === OS.ANDROID) {
					path = 'Android';
				} else if (os === OS.IOS) {
					path = 'iOS';
				}
				break;
			case Platform.CHROMEBOOK:
				path = 'Chromebook';
				break;
			case Platform.WEB:
				path = 'Web';
				break;
			default:
				break;
			}

			let url = null;

			if (path === null) {
				url = 'https://turning.force.com/support/s/article/ExamView-Student-App-User-Guide';
			} else {
				url = `https://turning.force.com/support/s/article/ExamView-Student-App-User-Guide/${path}`;
			}

			url = 'https://turning.force.com/support/s/article/ExamView-Student-App-User-Guide';

			this.$store.commit('setHelpUrl', url);
		},
	}
};
</script>

<style>
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2A2522;
}
</style>
