import Vue from 'vue';

let eventBus = null;

/* eslint-disable no-unused-vars */
export default {
	init(nativeEventBus) {
		this.setEventBus(nativeEventBus);

		window.electronAPI.handleWindowFocus((event) => {
			this.getEventBus().$emit('window-focus');
		});

		window.electronAPI.handleWindowBlur((event) => {
			this.getEventBus().$emit('window-blur');
		});

		window.electronAPI.handleA2AMessageReceived((message) => {
			this.getEventBus().$emit('a2a-message-received', message);
		});

		window.electronAPI.handleA2AConnection((res) => {
			this.getEventBus().$emit('a2a-connection-event', res);
		});

		Vue.$log.info('Electron platform initialized');
	},
	getEventBus() {
		return eventBus;
	},
	setEventBus(bus) {
		eventBus = bus;
	},
	getItem(key) {
		return localStorage.getItem(key);
	},
	setItem(key, value) {
		localStorage.setItem(key, value);
	},
	removeItem(key) {
		localStorage.removeItem(key);
	},
	exitApp() {
		throw new Error('Not implemented');
	},
	// app2app local
	a2aDisconnect() {
		window.electronAPI.a2aDisconnect();
	},
	a2aConnect(host, port) {
		window.electronAPI.a2aConnect(host, port);
	},
	a2aSendMessage(message) {
		window.electronAPI.a2aSendMessage(message);
	},
};
