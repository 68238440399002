<template>
  <div>
		<!-- eslint-disable-next-line max-len -->
		<b-modal id="ec-modal-1" v-model="showModal" size="lg" no-fade scrollable modal-class="modal-fullscreen">
			<template #modal-header="{}">
                <div class="w-100 fixNotchPaddingTop">
                    <!-- Emulate built in modal header close button action -->
                    <!-- eslint-disable-next-line max-len -->
                    <h5 v-if="!isLoading && !loadError" class="d-none d-md-block mt-2 documentTitle">{{ documentTitle }}</h5>
                    <b-button-group v-if="!isLoading && !loadError">
                        <b-link @click="page > 1 ? page-- : 1">
                            <i class="fa-solid fa-arrow-left"></i>
                            <!--{{ $t('common.back') }}-->
                        </b-link>
                        <!-- eslint-disable-next-line max-len -->
                        <b-link @click="page = 1" class="px-0">{{ page }} / {{ numPages ? numPages : '∞' }}</b-link>
                        <b-link @click="page < numPages ? page++ : 1">
                            <!--{{ $t('common.forward') }}-->
                            <i class="fa-solid fa-arrow-right"></i>
                        </b-link>
                    </b-button-group>

                    <b-button-group v-if="!isLoading && !loadError">
                        <b-link @click="scale -= scale > 0.2 ? 0.1 : 0">
                            <i class="fas fa-magnifying-glass-minus"></i>
                        </b-link>
                        <b-link class="px-0"
                            @click="scale='page-width'">{{ formattedZoom }} %</b-link>
                        <b-link @click="scale += scale < 2 ? 0.1 : 0">
                            <i class="fas fa-magnifying-glass-plus"></i>
                        </b-link>
                    </b-button-group>
                </div>
			</template>

			<div v-if="isLoading" class="text-center mb-3 screen-center">
				<b-spinner/>
				<!-- eslint-disable-next-line max-len -->
				<h5 class="mt-2 w-100 text-center">{{ $t('external-content.loading-content') }}</h5>
			</div>

			<div v-if="!isLoading && loadError" class="text-center screen-center">
				<i class="fas fa-exclamation-triangle fa-4x"></i>
				<h5 class="mt-2 w-100 text-center">{{ errorMessage }}</h5>
			</div>

			<div id="pdfvuer" v-if="!isLoading && !loadError">
				<pdf style="width: 100%; padding: 20px auto;"
					:src="pdfdata" v-for="i in numPages" :key="i" :id="i" :page="i"
					:scale.sync="scale"
					:annotation="true"
					:resize="false"
					@link-clicked="handle_pdf_link"/>
			</div>

			<template #modal-footer>
				<div class="w-100 fixNotchPaddingBottom">
					<b-button
						variant="primary"
						size="sm"
						class="float-right"
						@click="showModal = false"
					>
						{{ $t('common.buttons.close') }}
					</b-button>
				</div>
			</template>
		</b-modal>
  </div>

</template>

<script>
import pdfvuer from 'pdfvuer';
import native from '@/api/native';

export default {
	name: 'ExternalConent',
	props: ['qdef'],
	components: { pdf: pdfvuer },
	data() {
		return {
			page: 1,
			numPages: 0,
			pdfdata: undefined,
			errors: [],
			scale: 'page-width',
			documentTitle: null,
			showFullscreen: false,
			isLoading: true,
			showModal: false,
			errorMessage: null,
		};
	},
	computed: {
		formattedZoom() {
			return Number.parseInt(this.scale * 100, 10);
		},
	},
	methods: {
		isModalOpen() {
			return this.showModal;
		},
		hideModal() {
			this.showModal = false;
		},
		showContent(link) {
			this.showModal = true;
			this.resetContent();
			this.isLoading = true;
			this.showFullscreen = true;
			this.loadError = false;
			this.downloadContent(link, 0);
		},
		resetContent() {
			this.page = 1;
			this.numPages = 0;
			this.pdfdata = undefined;
			this.errors = [];
			this.scale = 'page-width';
		},
		handle_pdf_link(params) {
			// Scroll to the appropriate place on our page - the Y component of
			// params.destArray * (div height / ???), from the bottom of the page div
			const page = document.getElementById(String(params.pageNumber));
			page.scrollIntoView();
		},
		downloadContent(link, redirectCount) {
			const self = this;
			native.downloadFile(link, { responseType: 'arraybuffer' })
				.then((res) => {
					this.getPdf({ data: res.data });
				})
				.catch((error) => {
					if (error.status === 302 && error.headers.location && redirectCount < 5) {
						self.downloadContent(error.headers.location, redirectCount + 1);
						return;
					}
					console.log(error);
					self.loadError = true;
					self.isLoading = false;
					self.errorMessage = this.$t('external-content.content-not-loaded');
				});
		},
		getPdf(content) {
			this.isLoading = true;
			this.loadError = false;
			const self = this;

			self.pdfdata = pdfvuer.createLoadingTask(content);
			self.pdfdata.then((pdf) => {
				this.isLoading = false;
				this.loadError = false;

				self.numPages = pdf.numPages;

				pdf.getMetadata().then((stuff) => {
					// console.log(stuff); // Metadata object here
					// eslint-disable-next-line max-len
					self.documentTitle = stuff.info && stuff.info.title ? stuff.info.title : this.$t('external-content.default-title');
				}).catch((error) => {
					console.log(error);
					this.isLoading = false;
					this.loadError = true;
					this.errorMessage = this.$t('external-content.error-getting-metadata');
				});

				function changePage() {
					let i = 1; const
						count = Number(pdf.numPages);
					do {
						if (window.pageYOffset >= self.findPos(document.getElementById(i))
                && window.pageYOffset <= self.findPos(document.getElementById(i + 1))) {
							self.page = i;
						}
						i += 1;
					} while (i < count);
					if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
						self.page = i;
					}
				}

				window.onscroll = () => {
					changePage();
					// stickyNav();
				};
			}).catch((error) => {
				console.log(error);
				this.isLoading = false;
				this.loadError = true;
				this.errorMessage = this.$t('external-content.content-not-loaded');
			});
		},
		findPos(obj) {
			return obj.offsetTop;
		}
	},
	watch: {
		page(p) {
			if (window.pageYOffset <= this.findPos(document.getElementById(p))
				|| (document.getElementById(p + 1)
					&& window.pageYOffset >= this.findPos(document.getElementById(p + 1)))) {
				// window.scrollTo(0,this.findPos(document.getElementById(p)));
				document.getElementById(p).scrollIntoView();
			}
		}
	},
};
</script>

<style>
#ec-modal-1 .modal-dialog {
    max-width: 650px !important;;
}
#ec-modal-1 .modal-body {
    padding: 0 !important;
}
#ec-modal-1 .modal-header {
    align-items: center !important;
}
#ec-modal-1 .documentTitle {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 40%;
}
@media only screen and (max-width: 576px) {
	#ec-modal-1.modal-fullscreen .modal-dialog {
		max-width: 100%;
		margin: 0;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100vh;
		display: flex;
		position: fixed;
		z-index: 100000;
	}
	#ec-modal-1 .modal-dialog-scrollable {
		max-height: unset !important;
	}
	#ec-modal-1 .modal-dialog-scrollable .modal-content {
		max-height: unset !important;
	}
    #ec-modal-1 .modal-header {
        padding: 0.5rem 0 !important;
    }
    #ec-modal-1 .modal-header a {
        font-size: 1rem;
    }
}
.html-question img {
    max-width: 100%;
    height: auto;
}
.screen-center {
    position:absolute;
    top:40%;
    left:0;
    right:0;
}
</style>
