/*
import {
	SessionType,
	QuestionType,
	FlowQuestionType,
	Flow2QuestionType
} from '@/components/common/constants';
*/
// import XmlUtil from '@/components/util/XmlUtil';
// import AnswerUtil from '@/components/util/AnswerUtil';
import native from '@/api/native';

const exports = {};

/*
exports.createQuestion = (xml) => {
	if (!xml) {
		return null;
	}

	xml = xml.replaceAll('<![CDATA[', '');
	xml = xml.replaceAll(']]>', '');
	xml = xml.replaceAll('<akey>&lt;![CDATA[', '<akey>');
	xml = xml.replaceAll(']]&gt;</akey>', '</akey>');

	const questionId = XmlUtil.getValue(xml, 'qid');
	const questionName = encodeURI(XmlUtil.getValue(xml, 'qn'));
	let questionContent = decodeURI(XmlUtil.getValue(xml, 'qc'));
	const questionType = parseInt(XmlUtil.getValue(xml, 'qt'), 10);
	const questionSubtype = parseInt(XmlUtil.getValue(xml, 'qst'), 10);
	const useLetters = !(XmlUtil.getValue(xml, 'ul') === 'false');
	const allowMultipleChances = XmlUtil.getValue(xml, 'amc') === 'true';
	const forceAnswerPresentation = XmlUtil.getValue(xml, 'fap') === 'true';
	const givenAnswer = decodeURI(XmlUtil.getValue(xml, 'ga'));

	const answers = AnswerUtil.buildAnswerList(XmlUtil.getValue(xml, 'qa'));

	questionContent = XmlUtil.decodeHtmlEntities(questionContent);
	questionContent = `<!DOCTYPE html><html><body>${questionContent}</body></html>`;

	// Hacemos scrollable los iframe del contenido web proviniente de flow
	if (questionContent.indexOf('<iframe scrolling=\'no\'') > -1) {
		questionContent = questionContent.replaceAll('<iframe scrolling=\'no\'',
		'<iframe scrolling=\'yes\'');
	}

	if (questionContent.indexOf('<html>') === -1) {
		questionContent = `<!DOCTYPE html><html><head><meta http-equiv='Content-Type'
		content='text/html;charset=UTF-8'/></head><body>${questionContent}</body></html>`;
	}

	const urlImages = [];
	exports.createListUrlImages(questionContent, urlImages);

	let comment = '';
	let questionContentType;
	let questionExternalContent;
	let questionExternalContentType = '';

	if (window.$store.state.sessionType === SessionType.SESSION_TYPE_FLOW2
		|| window.$store.state.sessionType === SessionType.SESSION_TYPE_HOMEWORK) {
		// extraccion co (comment)
		comment = XmlUtil.getValue(xml, 'co');

		// ARREGLO nuevas etiquetas contenido
		// extraccion qct (content type)
		questionContentType = XmlUtil.getValue(xml, 'qct');

		// extraccion qec (content type)
		questionExternalContent = decodeURI(XmlUtil.getValue(xml, 'qec'));
		questionExternalContent = questionExternalContent.replaceAll('&#39;', '\'');

		// extraccion ct (content type)
		questionExternalContentType = XmlUtil.getValue(xml, 'qect');
	}

	return {
		questionId,
		questionName,
		questionContent,
		questionType,
		questionSubtype,
		useLetters,
		allowMultipleChances,
		forceAnswerPresentation,
		givenAnswer,
		answers,
		urlImages,
		comment,
		questionContentType,
		questionExternalContent,
		questionExternalContentType,
	};
};

exports.convertQuestionType = (key) => {
	if (window.$store.state.sessionType === SessionType.SESSION_TYPE_FLOW
		|| window.$store.state.sessionType === SessionType.SESSION_TYPE_WAVE) {
		if (key === QuestionType.Q_TYPE_TRUE_OR_FALSE) {
			return FlowQuestionType.FLOW_Q_TYPE_TRUE_OR_FALSE;
		}
		if (key === QuestionType.Q_TYPE_MULTIPLE_CHOICE) {
			return FlowQuestionType.FLOW_Q_TYPE_MULTIPLE_CHOICE;
		}
		if (key === QuestionType.Q_TYPE_MULTIPLE_ANSWER) {
			return FlowQuestionType.FLOW_Q_TYPE_MULTIPLE_ANSWER;
		}
		if (key === QuestionType.Q_TYPE_SHORT_TEXT) {
			return FlowQuestionType.FLOW_Q_TYPE_SHORT_TEXT;
		}
		if (key === QuestionType.Q_TYPE_NUMERIC) {
			return FlowQuestionType.FLOW_Q_TYPE_NUMERIC;
		}
		if (key === FlowQuestionType.FLOW2_Q_TYPE_INFORMATIVE) {
			return QuestionType.Q_TYPE_INFORMATIVE;
		}
		if (key === Flow2QuestionType.FLOW2_Q_TYPE_CONSTRUCTIVE) {
			return QuestionType.Q_TYPE_CONSTRUCTIVE;
		}
	}

	if (window.$store.state.sessionType === SessionType.SESSION_TYPE_HOMEWORK
		|| window.$store.state.sessionType === SessionType.SESSION_TYPE_HOMEWORK_EVAS
		|| window.$store.state.sessionType === SessionType.SESSION_TYPE_FLOW2
		|| window.$store.state.sessionType === SessionType.SESSION_TYPE_EVAS) {
		if (key === Flow2QuestionType.FLOW2_Q_TYPE_TRUE_OR_FALSE) {
			return QuestionType.Q_TYPE_TRUE_OR_FALSE;
		}
		if (key === Flow2QuestionType.FLOW2_Q_TYPE_MULTIPLE_CHOICE) {
			return QuestionType.Q_TYPE_MULTIPLE_CHOICE;
		}
		if (key === Flow2QuestionType.FLOW2_Q_TYPE_MULTIPLE_ANSWER) {
			return QuestionType.Q_TYPE_MULTIPLE_ANSWER;
		}
		if (key === Flow2QuestionType.FLOW2_Q_TYPE_SHORT_TEXT) {
			return QuestionType.Q_TYPE_SHORT_TEXT;
		}
		if (key === Flow2QuestionType.FLOW2_Q_TYPE_NUMERIC) {
			return QuestionType.Q_TYPE_NUMERIC;
		}
		if (key === Flow2QuestionType.FLOW2_Q_TYPE_CONSTRUCTIVE) {
			return QuestionType.Q_TYPE_CONSTRUCTIVE;
		}
		if (key === Flow2QuestionType.FLOW2_Q_TYPE_INFORMATIVE) {
			return QuestionType.Q_TYPE_INFORMATIVE;
		}
	}

	return -1;
};

exports.createListUrlImages = (questionContent, urlImages) => {
	let pos = 0;

	do {
		const urlImage = exports.getNextImage(pos, questionContent);

		if (urlImage && urlImage !== '') {
			urlImages.push(urlImage);
		}

		pos = exports.getNextPosImage(pos, questionContent);
	}
	while (exports.getNextPosImage(pos, questionContent) > 0);
};

exports.getNextImage = (pos, content) => {
	const imgPos = content.indexOf('<img', pos);

	if (imgPos < 0) {
		return null;
	}

	const srcPos = content.indexOf('src', imgPos);
	const endPos = content.indexOf('>', imgPos);

	if (srcPos >= endPos) {
		return null;
	}

	const qsPos = content.indexOf('"', srcPos);

	if (qsPos < 0 || qsPos > endPos) {
		return null;
	}

	const qePos = content.indexOf('"', qsPos + 1);

	if (qePos < 0 || qePos > endPos) {
		return null;
	}

	return content.substring(qsPos + 1, qePos);
};

exports.getNextPosImage = (pos, content) => {
	const imgPos = content.indexOf('<img', pos);

	if (imgPos < 0) {
		return 0;
	}

	const endPos = content.indexOf('>', imgPos);

	return endPos;
};

exports.buildQuestionList = (xml) => {
	const questions = [];

	if (!xml) {
		return questions;
	}

	let posI = -1;
	let posF = -1;
	let offset = 0;
	let temp;
	let q;

	posI = xml.indexOf('<qdata>', offset);
	posF = xml.indexOf('</qdata>', offset);

	while (posI !== -1 && posF !== -1) {
		temp = xml.substring(posI + 7, posF);
		q = exports.createQuestion(temp);

		questions.push(q);

		offset = posF + 1;
		posI = xml.indexOf('<qdata>', offset);
		posF = xml.indexOf('</qdata>', offset);
	}

	return questions;
};
*/

exports.injectBase64Images = (qdef) => {
	const q = {};
	Object.assign(q, qdef);

	let pos = 0;

	do {
		const imageKey = exports.getNextImage(pos, q.questionContent);
		if (imageKey !== null && imageKey !== '') {
			const base64Image = native.getItem(imageKey);
			q.questionContent = q.questionContent.replace(imageKey, base64Image);
		}
		pos = exports.getNextPosImage(pos, q.questionContent);
	} while (exports.getNextPosImage(pos, q.questionContent) > 0);

	return q;
};

exports.getNextImage = (pos, content) => {
	const { length } = 'evs-ot-img-EV-0A0A-0A0A@00000';

	const startPos = content.indexOf('evs-ot-img-', pos);

	if (startPos < 0) {
		return null;
	}

	const endPos = startPos + length;

	return content.substring(startPos, endPos);
};

exports.getNextPosImage = (pos, content) => {
	const startPos = content.indexOf('evs-ot-img-', pos);

	if (startPos < 0) {
		return 0;
	}

	return startPos;
};

export default exports;
