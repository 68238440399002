import { Mode, QuestionType, NetworkQuality } from '@/components/common/constants';
import native from '@/api/native';

export default {
	data() {
		return {
			mode: Mode,
			questionType: QuestionType,
			networkQuality: NetworkQuality,
		};
	},
	methods: {
		isMobile() {
			return native.isMobile();
		},
		getToastTitle() {
			let toastTitle = '???';
			if (this.$store.state.mode === this.mode.POLLING_SESSION) {
				toastTitle = this.$t('common.polling-session');
			} else if (this.$store.state.mode === this.mode.ONLINE_TEST) {
				toastTitle = this.$t('common.online-test');
			}
			return toastTitle;
		},
		showToast(message, type) {
			this.$root.$bvToast.toast(message, {
				title: this.getToastTitle(), solid: true, variant: type, autoHideDelay: 2000
			});
		},
		getQuestionType(type) {
			let text = null;

			switch (type) {
			case QuestionType.Q_TF_TYPE:
				text = this.$t('question.type-TF');
				break;
			case QuestionType.Q_MC_TYPE:
				text = this.$t('question.type-MC');
				break;
			case QuestionType.Q_MR_TYPE:
				text = this.$t('question.type-MR');
				break;
			case QuestionType.Q_NR_TYPE:
				text = this.$t('question.type-NR');
				break;
			case QuestionType.Q_SA_TYPE:
				text = this.$t('question.type-SA');
				break;
			default:
				text = '???';
				break;
			}

			return text;
		},
		getAnswerText(text) {
			let value = null;

			switch (text) {
			case 'True':
				value = this.$t('response.true');
				break;
			case 'False':
				value = this.$t('response.false');
				break;
			case 'Yes':
				value = this.$t('response.yes');
				break;
			case 'No':
				value = this.$t('response.no');
				break;
			default:
				value = text;
				break;
			}

			return value;
		},
	}
};
