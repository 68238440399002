import xml2js from 'xml2js';

export default class A2AAckMessage {
	constructor(rsok, messageId) {
		this.rsok = rsok;
		this.mid = messageId;

		const builder = new xml2js.Builder({ rootName: 'app2app' });
		this.serialize = () => {
			const clone = { ...this };
			delete clone.serialize;
			return builder.buildObject(clone);
		};
	}
}
