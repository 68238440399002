export default {
	safeDecode(content) {
		try {
			return decodeURIComponent(unescape(content));
		} catch (e) {
			try {
				return decodeURIComponent(content);
			} catch (e2) {
				return content;
			}
		}
	},
	safeEncode(content) {
		return encodeURIComponent(content);
	},
	replaceLinks(content, callback) {
		content = content.replace(/target=["|'](.*?)["|']/, '');
		content = content.replace(/href=["|'](?!#)(.*?)["|']/, (m, $1) => `href='#' onclick="${callback}('${$1}')"`);
		return content;
	}
};
