	<template>
  <div>
    <ModeSelector
			ref="ms"
			v-show="selectedMode == mode.NONE"
			@mode-changed="selectedMode = $event"/>
    <OnlineTest
			ref="ot"
			v-show="selectedMode == mode.ONLINE_TEST"
			@back="selectedMode = mode.NONE"/>
    <PollingSession
			ref="ps"
			v-show="selectedMode == mode.POLLING_SESSION"
			@back="selectedMode = mode.NONE"/>
  </div>
</template>

<script>
import { Mode } from '@/components/common/constants';
import BaseComponent from '@/components/common/BaseComponent';
import ModeSelector from '@/components/ModeSelector.vue';
import OnlineTest from '@/components/OnlineTest.vue';
import PollingSession from '@/components/PollingSession.vue';
import native from '@/api/native';

export default {
	name: 'Home',
	mixins: [BaseComponent],
	components: {
		ModeSelector,
		OnlineTest,
		PollingSession
	},
	data() {
		return {
		};
	},
	methods: {
		handleBackButton() {
			if (this.selectedMode === Mode.NONE) {
				this.$log.debug('exitApp() called!');
				native.exitApp();
			} else {
				let notContinue = false;
				if (this.selectedMode === Mode.ONLINE_TEST) {
					this.$log.debug('ONLINE_TEST handleBackButton() called!');
					notContinue = this.$refs.ot.handleBackButton();
				} else if (this.selectedMode === Mode.POLLING_SESSION) {
					this.$log.debug('POLLING_SESSION handleBackButton() called!');
					notContinue = this.$refs.ps.handleBackButton();
				}
				this.$log.debug(`notContinue = ${notContinue}`);
				if (!notContinue) {
					this.selectedMode = Mode.NONE;
				}
			}
		}
	},
	computed: {
		selectedMode: {
			get() {
				return this.$store.state.mode;
			},
			set(mode) {
				this.$store.commit('setMode', mode);
				this.$log.debug(`*** mode set to '${this.selectedMode}'`);

				if (mode === Mode.ONLINE_TEST) {
					this.$refs.ot.reset();
				} else if (mode === Mode.POLLING_SESSION) {
					this.$refs.ps.reset();
				} else {
					this.$refs.ms.reset();
				}
			}
		},
	},
	// eslint-disable-next-line no-unused-vars
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (vm.selectedMode === Mode.ONLINE_TEST) {
				vm.$refs.ot.reset();
			} else if (vm.selectedMode === Mode.POLLING_SESSION) {
				vm.$refs.ps.reset();
			} else {
				vm.$refs.ms.reset();
			}
			native.getEventBus().$on('back-button', vm.handleBackButton);
		});
	},
	// eslint-disable-next-line no-unused-vars
	beforeRouteLeave(to, from, next) {
		this.$refs.ps.unregisterApp2AppMessages();
		native.getEventBus().$off('back-button');
		next();
	},
};
</script>
