import xml2js from 'xml2js';

export default class A2ACheckResponseMessage {
	constructor(xml) {
		xml = xml.replace('<items><![CDATA[<message>', '<items><message>');
		xml = xml.replace('</message>]]></items>', '</message></items>');

		this.maxAck = null;
		this.message = null;
		this.rsok = false;
		this.items = null;
		this.users = null;
		this.id = null;

		xml2js.parseString(xml, { explicitRoot: false, explicitArray: false }, (err, result) => {
			if (result) {
				this.maxAck = result.mack;
				this.message = result.msg;
				this.rsok = result.rsok === 'true';

				if (result.items && result.items.message) {
					this.items = Array.isArray(result.items.message)
						? result.items.message : [result.items.message];
				} else {
					this.items = [];
				}

				this.users = result.users;
				this.id = result.$.id;
			}
		});
	}
}
