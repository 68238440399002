const exports = {};

exports.getValue = (xml, tag) => {
	let posT = -1;
	let posC = -1;
	let posF = -1;
	let isEmpty = -1;

	// **************************************
	// proceso de etiquetas vacias
	isEmpty = xml.indexOf(`<${tag} />`);
	if (isEmpty > -1) return '';
	isEmpty = xml.indexOf(`<${tag}/>`);
	if (isEmpty > -1) return '';
	// **************************************

	posT = xml.indexOf(`<${tag}`);
	if (posT === -1) {
		return '';
	}

	posC = xml.indexOf('>', posT + 1);
	if (posC === -1) {
		return '';
	}

	posF = xml.indexOf(`</${tag}`, posC + 1);
	if (posF === -1) {
		return '';
	}

	let res = xml.substring(posC + 1, posF);

	if (res.indexOf('<![CDATA') === 0 && res.length > 11) {
		res = res.substring(9, res.length - 3);
	}

	return res;
};

exports.decodeHtmlEntities = (xml) => {
	let result;

	result = xml.replaceAll('&lt;', '<');
	result = result.replaceAll('&gt;', '>');
	result = result.replaceAll('&amp;', '&');
	result = result.replaceAll('&quot;', '"');
	result = result.replaceAll('&apos;', '\'');
	result = result.replaceAll('&lt;', '<');
	result = result.replaceAll('&gt;', '>');

	return result;
};

exports.getCurrentDateWithFormat = () => {
	const date = new Date();
	return `${date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
};

// eslint-disable-next-line no-unused-vars
exports.storeImage = (url, nameImage, folderNameHomeworkId) => {
	const pathImage = '';

	/* const img = new Image();
	img.src = 'mypicture.png';

	// eslint-disable-next-line func-names
	img.load = function () {
		const canvas = document.createElement('canvas');
		document.body.appendChild(canvas);
		const context = canvas.getContext('2d');
		context.drawImage(img, 0, 0);
		const data = context.getImageData(x, y, img.width, img.height).data;
		localStorage.setItem(`${folderNameHomeworkId}-${questionId}-image-${i}`, data);
	}; */
	/* InputStream is;

	try {
		is = (InputStream) new URL(url).getContent();
				Drawable d = Drawable.createFromStream(is, "src name");
				BitmapDrawable drawable = (BitmapDrawable) d;
				Bitmap bitmap = drawable.getBitmap();
		File homeworkDirectory = getFolderPath(folerNameHomeworkId, context);
				File image = new File(homeworkDirectory, nameImage);

				// Encode the file as a PNG image.
				FileOutputStream outStream;
				try {

						outStream = new FileOutputStream(image);
						bitmap.compress(Bitmap.CompressFormat.PNG, 100, outStream);
							// 100 to keep full quality of the image

						outStream.flush();
						outStream.close();
						pathImage = image.getAbsolutePath();
				} catch (FileNotFoundException e) {
						e.printStackTrace();
				} catch (IOException e) {
						e.printStackTrace();
				}
	} catch (IOException e) {
		// TODO Auto-generated catch block
		e.printStackTrace();
	} */

	return pathImage;
};

exports.getNameImageFromUrl = (url) => {
	const textToSearch = 'externalId=';
	// const sizeUrl = url.length();
	let nameImage = '';

	const posStart = url.indexOf(textToSearch);

	if (posStart >= 0) {
		nameImage = url.substring(posStart + textToSearch.length());
	}

	return nameImage;
};

// eslint-disable-next-line no-unused-vars
exports.storeHomeworkXml = (xml) => {
	const success = false;

	/* File fileXml = new File(getFolderPath(folderNameHomeworkId), COMMON_XML_FILE);

	// Encode the file as a PNG image.
	FileOutputStream outStream;

	try {
		outStream = new FileOutputStream(fileXml);
		outStream.write(xml.getBytes());

		outStream.flush();
		outStream.close();
		success = true;
	} catch (FileNotFoundException e) {
		e.printStackTrace();
	} catch (IOException e) {
		e.printStackTrace();
	} catch (Exception e) {
		e.printStackTrace();
	} */

	return success;
};

exports.getBase64Image = (url, msgCB) => {
	const img = new Image();
	img.onload = () => {
		const canvas = document.createElement('CANVAS');
		const ctx = canvas.getContext('2d');
		canvas.width = img.width;
		canvas.height = img.height;
		ctx.drawImage(img, 0, 0);
		const dataURL = canvas.toDataURL('image/png', 1);
		msgCB(dataURL);
	};

	// set attributes and src
	img.setAttribute('crossOrigin', 'anonymous');
	img.src = url;
};

export default exports;
