import Vue from 'vue';
import A2AConnectResponseMessage from '../../model/A2AConnectResponseMessage';
import A2ACheckResponseMessage from '../../model/A2ACheckResponseMessage';
import A2AAckMessage from '../../model/A2AAckMessage';
import A2APostMessage from '../../model/A2APostMessage';
import native from '../native';

let sessionConnected = false;
let processMessagesCallback = null;
let connectionCallback = null;
let remoteEndpoint = null;
let postMessageCallback = null;
let networkQualityCallback = null;
let lastAck;
let remoteSessionId;
let connectionRetry = false;
const connectionRetryDelay = 20000;
let connectionRetryTimeout = null;

export default {
	/// //////////////////////////////////////////////////////////////
	// UTILITIES
	isConnected() {
		return sessionConnected;
	},
	setConnected(connected) {
		sessionConnected = connected;
	},
	setLastAck(ack) {
		lastAck = ack;
	},
	getLastAck() {
		return lastAck;
	},
	getSessionId() {
		return remoteSessionId;
	},
	setSessionId(id) {
		remoteSessionId = id;
	},
	getProcessMessagesCallback() {
		return processMessagesCallback;
	},
	setProcessMessagesCallback(callback) {
		processMessagesCallback = callback;
	},
	getRemoteEndpoint() {
		return remoteEndpoint;
	},
	setRemoteEndpoint(endpoint) {
		remoteEndpoint = endpoint;
	},
	getConnectionCallback() {
		return connectionCallback;
	},
	setConnectionCallback(callback) {
		connectionCallback = callback;
	},
	getPostMessageCallback() {
		return postMessageCallback;
	},
	setPostMessageCallback(callback) {
		postMessageCallback = callback;
	},
	getNetworkQualityCallback() {
		return networkQualityCallback;
	},
	setNetworkQualityCallback(callback) {
		networkQualityCallback = callback;
	},
	getConnectionRetry() {
		return connectionRetry;
	},
	setConnectionRetry(enabled) {
		connectionRetry = enabled;
		if (connectionRetryTimeout) {
			clearTimeout(connectionRetryTimeout);
			connectionRetryTimeout = null;
		}
	},
	/// //////////////////////////////////////////////////////////////
	// SESSION MANAGEMENT
	connect(connectMessage, host, port, connCallback, messageCallback,
		qualityCallback, postCallback, cbok, cberr) {
		this.setProcessMessagesCallback(messageCallback);
		this.setConnectionCallback(connCallback);
		this.setNetworkQualityCallback(qualityCallback);
		this.setPostMessageCallback(postCallback);
		this.setConnectionRetry(true);

		Vue.$log.debug('A2aLocal', `connecting ${host}:${port}`);

		native.getEventBus().$off('a2a-connection-event');
		native.getEventBus().$on('a2a-connection-event', (event) => {
			const responseMessage = new A2AConnectResponseMessage();
			this.setConnected(event.connected);
			this.setRemoteEndpoint(`${host}:${port}`);
			this.setSessionId(connectMessage.sinfo.siid);

			responseMessage.rsok = event.connected;
			responseMessage.message = event.message;
			responseMessage.endpoint = this.getRemoteEndpoint();

			this.getConnectionCallback()(responseMessage);
			native.a2aSendMessage(connectMessage.serialize());

			if (cbok && responseMessage.rsok) {
				cbok(responseMessage);
			} else if (cberr && !responseMessage.rsok) {
				cberr(event.message);
			}

			if (event.connected === false) {
				this.handleConnectionRetry(connectMessage, host, port, connCallback, messageCallback,
					qualityCallback, postCallback, cbok, cberr);
			}
		});

		native.getEventBus().$off('a2a-message-received');
		native.getEventBus().$on('a2a-message-received', (data) => {
			Vue.$log.debug('A2aLocal', data);
			const crm = new A2ACheckResponseMessage(data);
			if (crm.rsok) {
				this.setLastAck(crm.maxAck);
				this.processMessages(crm.items);
				this.sendAck(crm.id);
			}
		});

		try {
			native.a2aConnect(host, port);
		} catch (e) {
			if (cberr) {
				cberr(e.message);
			}
		}
	},
	handleConnectionRetry(connectMessage, host, port, connCallback, messageCallback,
		qualityCallback, postCallback, cbok, cberr) {
		if (this.getConnectionRetry() === false) {
			return;
		}
		const me = this;
		connectionRetryTimeout = setTimeout(() => {
			if (me.getConnectionRetry() === false) {
				return;
			}
			me.connect(connectMessage, host, port, connCallback, messageCallback,
				qualityCallback, postCallback, cbok, cberr);
		}, connectionRetryDelay);
	},
	disconnect() {
		this.setConnectionRetry(false);
		native.a2aDisconnect();
		Vue.$log.debug('A2aLocal', 'disconnected');
	},
	/// //////////////////////////////////////////////////////////////
	// POST MESSAGE
	postMessage(destinationUserId, messageType, messageContent, userMessageId /* , cbok, cberr */) {
		const postMessage = new A2APostMessage('',
			destinationUserId, messageType, messageContent, userMessageId);
		native.a2aSendMessage(postMessage.serialize());
	},
	/// //////////////////////////////////////////////////////////////
	// RECEIVE MESSAGE
	processMessages(messages) {
		this.getProcessMessagesCallback()(messages);
	},
	sendAck(messageId) {
		const ack = new A2AAckMessage(true, messageId);
		native.a2aSendMessage(ack.serialize());
	}
};
