export default class A2AMessageInfo {
	constructor(msg) {
		this.sourceUserId = msg.suid;
		this.messageContent = msg.mc;
		this.messageType = msg.mt;
		this.sentTime = new Date();
		this.messageId = msg.mid;
		this.userMessageId = msg.umid;
	}
}
