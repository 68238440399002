<template>
	<div v-if="items.length > 0" class="mt-5" :disabled="disabled">
		<span class="d-block text-center">{{ $t('online-test.select-local-test') }}</span>
		<b-list-group ref="parent" class="list mt-2">
			<b-list-group-item
			v-for="(item, index) in items" :key="items[index].id"
      style="padding: 0.25rem 0.85rem;">
				<div class="item"
        style="padding: 0.25rem 1rem;"
        @click="$emit('connect-item', { id: items[index].id, xml: items[index].xml })">
					<div
          style="font-size: 0.875rem; color: #2A2522; font-weight: normal;">
						{{ items[index].id }}<br/>
						<span style="font-size: 0.75rem;">
							{{ items[index].date }}
						</span>
					</div>
					<div onclick="event.stopPropagation();">
						<i class="fa-solid fa-trash-can fa-sm mr-2"
						@click="$emit('remove-item', items[index].id)"></i>
						<i class="fa-solid fa-arrow-right-to-bracket"
						@click="$emit('connect-item', { id: items[index].id, xml: items[index].xml })"></i>
					</div>
				</div>
			</b-list-group-item>
		</b-list-group>
	</div>
</template>

<script>
import XmlUtil from '@/components/util/XmlUtil';

export default {
	name: 'LocalOnlineTestList',
	props: ['data', 'disabled'],
	data() {
		return {
			items: [],
		};
	},
	methods: {
		getXmlValue(xml, value) {
			return XmlUtil.getValue(xml, value);
		},
		getStoredDate(xml) {
			const storedDate = XmlUtil.getValue(xml, 'storedDate');

			if (this.$i18n.locale === 'en') {
				return storedDate;
			}

			const date = storedDate.split(' ')[0];
			const time = storedDate.split(' ')[1];

			const month = date.split('/')[0];
			const day = date.split('/')[1];
			const year = date.split('/')[2];

			return `${day}/${month}/${year} ${time}`;
		}
	},
	watch: {
		data() {
			this.items = [];

			for (let i = 0; i < this.data.length; i += 1) {
				const xml = this.data[i];
				this.items.push({ id: this.getXmlValue(xml, 'homeworkId'), date: this.getStoredDate(xml), xml });
			}
		}
	}
};
</script>

<style scoped>
.list {
	border: none;
}
.item {
	display: flex;
	justify-content: space-between;
	border: 1px solid #E5E5E5;
	border-radius: 5px;
    padding: 0.25rem 1.25rem;
    border-bottom-width: 1px;
}
.list-group-item {
    border-bottom-width: 0;
}
.list-group-item + .list-group-item {
    border-top-width: 0;
    margin-top: -1px;
}
.item i {
	border: 0;
	padding-top: 14px;
	font-size: 1.1rem;
    color: #707070;
}
.item span {
    color: #757575;
}
.circular-button {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-left: 5px;
	display: flex;
    align-items: center;
    justify-content: center;
}
</style>
