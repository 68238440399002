import xml2js from 'xml2js';
import QuestionDefinition from './QuestionDefinition';

export default class TestDefinition {
	constructor(xml) {
		this.shuffleQuestions = false;
		this.questions = [];

		xml2js.parseString(xml, { explicitRoot: false, explicitArray: false }, (err, result) => {
			if (result) {
				this.shuffleQuestions = result.sq === 'true';

				let qdefs = [];

				if (result.questions) {
					qdefs = Array.isArray(result.questions.qdata)
						? result.questions.qdata : [result.questions.qdata];
				} else {
					this.questions = [];
				}

				for (let i = 0; i < qdefs.length; i += 1) {
					const qDef = new QuestionDefinition(null);
					qDef.fillEntity(qdefs[i]);

					this.questions.push(qDef);
				}

				if (this.shuffleQuestions) {
					this.doShuffleQuestions(this.questions);
				}
			}
		});
	}

	fillEntity(tdef) {
		this.shuffleQuestions = tdef.sq === 'true';

		for (let q = 0; q < tdef.questions.qdata.length; q += 1) {
			const qDef = new QuestionDefinition(null);
			qDef.fillEntity(tdef.questions.qdata[q]);

			this.questions.push(qDef);
		}

		if (this.shuffleQuestions) {
			this.doShuffleQuestions(this.questions);
		}
	}

	doShuffleQuestions = (questions) => {
		const shuffle = (array) => {
			for (let i = array.length - 1; i > 0; i -= 1) {
				const j = Math.floor(Math.random() * (i + 1));
				const temp = array[i];
				array[i] = array[j];
				array[j] = temp;
			}
		};

		shuffle(questions);
	}

	getNumImages() {
		let count = 0;

		for (let i = 0; i < this.questions.length; i += 1) {
			const q = this.questions[i];
			count += q.urlImages.length;
		}

		return count;
	}
}
