import axios from 'axios';

export const HTTP = axios.create({
	headers: {
		'content-type': 'application/json'
	}
});

export const UTILS = {
	downloadDocument(response) {
		const link = document.createElement('a');

		const fileName = response.headers['x-file-name'];
		const contentType = response.headers['content-type'];
		const blob = new Blob([response.data], { type: contentType });

		// Soporte IE
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blob, fileName);
			return;
		}

		const url = window.URL.createObjectURL(blob);
		link.href = url;
		link.download = fileName;
		document.body.appendChild(link);
		link.click();

		setTimeout(() => {
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		}, 1000);
	}

};
