import xml2js from 'xml2js';
import a2aUtils from './A2AUtils';

export default class ProtectedSessionInfo {
	constructor(xml) {
		this.pass = null;
		this.appId = null;

		xml = `<app2app>${xml}</app2app>`;

		xml2js.parseString(a2aUtils.safeDecode(xml),
			{ explicitRoot: false, explicitArray: false }, (err, result) => {
				if (result) {
					this.pass = result.pass;
					this.appId = result['app-id'];
				}
			});
	}
}
