import xml2js from 'xml2js';

export default class A2APostResponseMessage {
	constructor(xml) {
		this.id = null;
		this.message = null;
		this.rsok = false;
		this.userMessageId = null;

		xml2js.parseString(xml, { explicitRoot: false, explicitArray: false }, (err, result) => {
			if (result) {
				this.id = result.$.id;
				this.message = result.msg;
				this.rsok = result.rsok === 'true';
				this.userMessageId = result.umid;
			}
		});
	}
}
