import Vue from 'vue';
import { NetworkQuality } from '@/components/common/constants';
import { HTTP } from '../http-common';
import discovery from '../../discovery';
import A2AConnectResponseMessage from '../../model/A2AConnectResponseMessage';
import A2ACheckMessage from '../../model/A2ACheckMessage';
import A2ACheckResponseMessage from '../../model/A2ACheckResponseMessage';
// import A2AMessageInfo from '../../model/A2AMessageInfo';
import A2APostMessage from '../../model/A2APostMessage';
import A2APostResponseMessage from '../../model/A2APostResponseMessage';

let sessionToken = null;
let sessionConnected = false;
let remoteEndpoint = null;
let remoteSessionId = null;
let checkMessagesTimer = null;
// eslint-disable-next-line prefer-const,no-unused-vars
let checkMessagesEnabled = false;
// eslint-disable-next-line no-unused-vars
const checkMessagesPeriod = 2000;
// const pingPeriod = 120 * 1000;
let lastAck = 0;
const messageQueue = [];
let numCheckMessageErrors = null;
let checkMessageErrorLevel = null;
let processMessagesCallback = null;
let connectionCallback = null;
let postMessageCallback = null;
let networkQualityCallback = null;

export default {
	getCommonHeaders() {
		let headers = {};

		headers = {
			// 'authorization': 'Bearer ' + window.kc_token
			'Content-type': 'application/x-www-form-urlencoded'
		};

		if (/MSIE|Trident/.test(window.navigator.userAgent)) {
			headers.Pragma = 'no-cache';
			headers['Cache-Control'] = 'no-cache, no-store';
		}

		return { headers };
	},
	genericGet(service, cbok, cberr) {
		HTTP
			.get(service,
				this.getCommonHeaders())
			.then((response) => {
				cbok(response.data);
			}).catch((error) => {
				cberr(error);
			});
	},
	genericPut(service, data, cbok, cberr) {
		HTTP
			.put(service,
				data, this.getCommonHeaders())
			.then((response) => {
				if (cbok) cbok(response.data);
			}).catch((error) => {
				if (cberr) cberr(error);
			});
	},
	genericPost(service, data, cbok, cberr) {
		HTTP
			.post(service,
				data, this.getCommonHeaders())
			.then((response) => {
				cbok(response.data);
			}).catch((error) => {
				cberr(error);
			});
	},
	genericDelete(service, cbok, cberr) {
		HTTP
			.delete(service,
				this.getCommonHeaders())
			.then((response) => {
				cbok(response.data);
			}).catch((error) => {
				cberr(error);
			});
	},
	/// //////////////////////////////////////////////////////////////
	// UTILITIES
	postBody(sessionId, message) {
		return `srid=FREE_TEST&ssid=${sessionId}&p=${message.serialize()}`;
	},
	basePath() {
		return `${discovery.a2a_global_endpoint}/App2AppGlobal/`;
	},
	getSessionToken() {
		return sessionToken;
	},
	setSessionToken(token) {
		sessionToken = token;
	},
	getRemoteEndpoint() {
		return remoteEndpoint;
	},
	setRemoteEndpoint(endpoint) {
		remoteEndpoint = `${endpoint}/`;
	},
	getSessionId() {
		return remoteSessionId;
	},
	setSessionId(id) {
		remoteSessionId = id;
	},
	isConnected() {
		return sessionConnected;
	},
	setConnected(connected) {
		sessionConnected = connected;
	},
	getCheckMessagesPeriod() {
		return checkMessagesPeriod;
	},
	setLastAck(ack) {
		lastAck = ack;
	},
	getLastAck() {
		return lastAck;
	},
	getNumCheckMessageErrors() {
		return numCheckMessageErrors;
	},
	setNumCheckMessageErrors(numErrors) {
		numCheckMessageErrors = numErrors;
	},
	getCheckMessageErrorLevel() {
		return checkMessageErrorLevel;
	},
	setCheckMessageErrorLevel(errorLevel) {
		checkMessageErrorLevel = errorLevel;
	},
	getProcessMessagesCallback() {
		return processMessagesCallback;
	},
	setProcessMessagesCallback(callback) {
		processMessagesCallback = callback;
	},
	getConnectionCallback() {
		return connectionCallback;
	},
	setConnectionCallback(callback) {
		connectionCallback = callback;
	},
	getPostMessageCallback() {
		return postMessageCallback;
	},
	setPostMessageCallback(callback) {
		postMessageCallback = callback;
	},
	getNetworkQualityCallback() {
		return networkQualityCallback;
	},
	setNetworkQualityCallback(callback) {
		networkQualityCallback = callback;
	},
	/// //////////////////////////////////////////////////////////////
	// SESSION MANAGEMENT
	connect(connectMessage, connCallback, messageCallback,
		qualityCallback, postCallback, cbok, cberr) {
		this.setProcessMessagesCallback(messageCallback);
		this.setConnectionCallback(connCallback);
		this.setNetworkQualityCallback(qualityCallback);
		this.setPostMessageCallback(postCallback);
		this.genericPost(`${this.basePath()}Connect`, this.postBody(connectMessage.sinfo.siid, connectMessage), (res) => {
			const responseMessage = new A2AConnectResponseMessage(res);
			this.setConnected(responseMessage.rsok);
			this.setSessionToken(responseMessage.token);
			this.setRemoteEndpoint(responseMessage.endpoint);
			this.setSessionId(connectMessage.sinfo.siid);

			this.setNumCheckMessageErrors(0);
			this.setCheckMessageErrorLevel(NetworkQuality.OK_QUALITY);

			if (responseMessage.rsok === true) {
				this.startCheckingMessages();
			} else {
				this.stopCheckingMessages();
			}

			this.getConnectionCallback()(responseMessage);
			if (cbok) {
				cbok(responseMessage);
			}
		}, cberr);
	},
	/// //////////////////////////////////////////////////////////////
	// POST MESSAGE
	postMessage(destinationUserId, messageType, messageContent, userMessageId, cbok, cberr) {
		const postMessage = new A2APostMessage(this.getSessionToken(),
			destinationUserId, messageType, messageContent, userMessageId);
		this.genericPost(`${this.getRemoteEndpoint()}PostMessage`, this.postBody(this.getSessionId(), postMessage), (res) => {
			const responseMessage = new A2APostResponseMessage(res);
			this.getPostMessageCallback()(responseMessage);
			if (cbok) {
				cbok(responseMessage);
			}
		}, cberr);
	},
	disconnect() {
		this.setConnected(false);
		this.stopCheckingMessages();
		console.log('app2app - disconnected!');
	},
	/// //////////////////////////////////////////////////////////////
	// RECEIVE MESSAGE
	getMessageQueue() {
		return messageQueue;
	},
	getPendingMessage() {
		return this.getMessageQueue().shift();
	},
	getPendingMessageCount() {
		return this.getMessageQueue().length;
	},
	startCheckingMessages() {
		this.checkMessagesEnabled = true;
		this.scheduleCheckMessage();
	},
	stopCheckingMessages() {
		this.checkMessagesEnabled = false;
		if (checkMessagesTimer == null) {
			clearTimeout(checkMessagesTimer);
			checkMessagesTimer = null;
		}
	},
	checkMessages(vm) {
		vm.checkMessagesTimer = null;
		const checkMessage = new A2ACheckMessage(vm.getSessionToken(), vm.getLastAck(), true);
		vm.genericPost(`${vm.getRemoteEndpoint()}CheckMessages`, vm.postBody(vm.getSessionId(), checkMessage), (res) => {
			const crm = new A2ACheckResponseMessage(res);
			if (crm.rsok) {
				if (crm.maxAck && crm.maxAck !== 'null') {
					vm.setLastAck(crm.maxAck);
				}
				vm.processMessages(crm.items);
				vm.handleCheckMessageErrors(true);
			} else {
				vm.handleCheckMessageErrors(false);
			}
			vm.scheduleCheckMessage();
		}, (error) => {
			console.log(error);
			vm.scheduleCheckMessage();
			vm.handleCheckMessageErrors(false);
		});
	},
	handleCheckMessageErrors(result) {
		let numErrors = this.getNumCheckMessageErrors();

		numErrors += result ? -1 : 1;

		if (numErrors < 0) {
			numErrors = 0;
		}

		this.setNumCheckMessageErrors(numErrors);

		const curErrorLevel = this.getCheckMessageErrorLevel();

		let newErrorLevel = '';

		if (numErrors >= 0 && numErrors <= 2) {
			newErrorLevel = NetworkQuality.OK_QUALITY;
		} else if (numErrors >= 3 && numErrors <= 9) {
			newErrorLevel = NetworkQuality.WARNING_QUALITY;
		} else {
			newErrorLevel = NetworkQuality.ERROR_QUALITY;
		}

		this.setCheckMessageErrorLevel(newErrorLevel);

		if (newErrorLevel !== curErrorLevel) {
			this.getNetworkQualityCallback()({ level: newErrorLevel, errors: numErrors });
		}
	},
	processMessages(messages) {
		if (!messages || !messages.length || messages.length === 0) {
			return;
		}

		Vue.$log.debug('A2ARemote', messages);
		this.getProcessMessagesCallback()(messages);
	},
	scheduleCheckMessage() {
		if (this.checkMessagesEnabled === true) {
			if (this.checkMessagesTimer == null) {
				const self = this;
				this.checkMessagesTimer = setTimeout(() => self.checkMessages(self),
					this.getCheckMessagesPeriod());
			}
		}
	}
};
