let client;
let messageCallback;
let connectionCallback;
let connected = false;
let msgbuffer = '';
const decoder = new TextDecoder('utf-8');

module.exports = {
	isConnected() {
		return connected;
	},
	getMessageBuffer() {
		return msgbuffer;
	},
	setMessageBuffer(buffer) {
		msgbuffer = buffer;
	},
	connect(host, port) {
		const me = this;
		me.setMessageBuffer('');

		if (client) {
			try {
				client.shutdownWrite();
			} catch (e) { console.log(e); }
		}
		// eslint-disable-next-line no-undef
		client = new Socket();

		/* client.setTimeout(timeout || 10000, () => {
			if (connectionCallback) {
				connectionCallback({ connected: false, message: 'Socket timeout' });
				client.removeAllListeners();
				client.end();
			}
		}); */

		client.open(host, port, () => {
			connected = true;
			if (connectionCallback) {
				connectionCallback({ connected: true, message: `TCP connection established with ${host}:${port}` });
			}
		}, (ex) => {
			if (connectionCallback) {
				connectionCallback({ connected: false, message: ex });
			}
		});

		client.onClose = function (hasError) {
			if (connectionCallback) {
				connectionCallback({ connected: false, message: `Socket closed:${hasError}` });
			}
		};

		client.onError = function (errorMessage) {
			if (connectionCallback) {
				connectionCallback({ connected: false, message: errorMessage });
			}
		};

		client.onData = function (data) {
			const chunk = decoder.decode(data);
			let buffer = me.getMessageBuffer() + chunk.toString().replace('\n', '');
			let separator = buffer.indexOf('|');
			let pos = 0;
			while (separator >= 0) {
				pos = buffer.indexOf('#');
				if (pos < separator) {
					const nextMessage = buffer.substring(pos + 1, separator);
					messageCallback(nextMessage);
					buffer = buffer.substring(separator + 1);
					separator = buffer.indexOf('|');
				} else {
					buffer = '';
					separator = -1;
				}
			}
			me.setMessageBuffer(buffer);
		};
	},
	sendMessage(message) {
		try {
			const dataString = `#${message}|\n`;
			const data = new Uint8Array(dataString.length);
			for (let i = 0; i < data.length; i += 1) {
				data[i] = dataString.charCodeAt(i);
			}
			client.write(data);
		} catch (e) {
			console.log(e);
		}
	},
	disconnect() {
		try {
			connected = false;
			client.shutdownWrite();
			/* if (connectionCallback) {
                connectionCallback({connected: false, message: 'disconnected'});
            } */
		} catch (e) {
			console.log(e);
		}
	},
	setMessageCallback(callback) {
		messageCallback = callback;
	},
	setConnectionCallback(callback) {
		connectionCallback = callback;
	}
};
