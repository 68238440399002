import xml2js from 'xml2js';
import TestDefinition from './TestDefinition';

export default class OnlineTestDefinition {
	constructor(xml) {
		this.homeworkId = null;
		this.status = null;
		this.testDefinition = null;

		xml2js.parseString(xml, { explicitRoot: false, explicitArray: false }, (err, result) => {
			if (result) {
				this.homeworkId = result.homeworkId;
				this.status = result.status;
				this.testDefinition = new TestDefinition(null);
				this.testDefinition.fillEntity(result.tdef);

				for (let i = 0; i < this.testDefinition.questions.length; i += 1) {
					const q = this.testDefinition.questions[i];
					q.getUrlImages();
				}
			}
		});
	}
}
