import { render, staticRenderFns } from "./LocalOnlineTestList.vue?vue&type=template&id=27f5c027&scoped=true&"
import script from "./LocalOnlineTestList.vue?vue&type=script&lang=js&"
export * from "./LocalOnlineTestList.vue?vue&type=script&lang=js&"
import style0 from "./LocalOnlineTestList.vue?vue&type=style&index=0&id=27f5c027&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f5c027",
  null
  
)

export default component.exports