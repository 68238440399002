import Vue from 'vue';
import a2aRemote from './app2app/app2appRemote';
import a2aLocal from './app2app/app2appLocal';
import discovery from '../discovery';
import A2AUserInfo from '../model/A2AUserInfo';
import A2ASessionInfo from '../model/A2ASessionInfo';
import A2AConnectMessage from '../model/A2AConnectMessage';
// import A2AConnectResponseMessage from '../model/A2AConnectResponseMessage';
// import A2ACheckMessage from '../model/A2ACheckMessage';
// import A2ACheckResponseMessage from '../model/A2ACheckResponseMessage';
import A2AMessageInfo from '../model/A2AMessageInfo';
// import A2APostMessage from '../model/A2APostMessage';
// import A2APostResponseMessage from '../model/A2APostResponseMessage';

const eventBus = new Vue();
let remoteEndpoint = null;
let remoteSessionId = null;
let lastAck = 0;
const messageQueue = [];
const messageIdBucket = [];
let numCheckMessageErrors = null;
let checkMessageErrorLevel = null;
let currentConnectMessage = null;
let a2aEnabled = false;

export default {
	/// //////////////////////////////////////////////////////////////
	// UTILITIES
	postBody(sessionId, message) {
		return `srid=FREE_TEST&ssid=${sessionId}&p=${message.serialize()}`;
	},
	getEventBus() {
		return eventBus;
	},
	getRemoteEndpoint() {
		return remoteEndpoint;
	},
	setRemoteEndpoint(endpoint) {
		remoteEndpoint = `${endpoint}/`;
	},
	getSessionId() {
		return remoteSessionId;
	},
	setSessionId(id) {
		remoteSessionId = id;
	},
	isConnected() {
		return a2aRemote.isConnected() || a2aLocal.isConnected();
	},
	isEnabled() {
		return a2aEnabled;
	},
	setEnabled(enabled) {
		a2aEnabled = enabled;
		messageIdBucket.length = 0;
	},
	getMessageIdBucket() {
		return messageIdBucket;
	},
	setLastAck(ack) {
		lastAck = ack;
	},
	getLastAck() {
		return lastAck;
	},
	getNumCheckMessageErrors() {
		return numCheckMessageErrors;
	},
	setNumCheckMessageErrors(numErrors) {
		numCheckMessageErrors = numErrors;
	},
	getCheckMessageErrorLevel() {
		return checkMessageErrorLevel;
	},
	setCheckMessageErrorLevel(errorLevel) {
		checkMessageErrorLevel = errorLevel;
	},
	getCurrentConnectMessage() {
		return currentConnectMessage;
	},
	setCurrentConnectMessage(errorLevel) {
		currentConnectMessage = errorLevel;
	},
	/// //////////////////////////////////////////////////////////////
	// SESSION MANAGEMENT
	connect(sessionId, deviceId, cbok, cberr) {
		const userInfo = new A2AUserInfo(deviceId, `User ${deviceId}`, 0, 'join');
		const sessionInfo = new A2ASessionInfo(`${discovery.a2a_session_prefix}${sessionId}`, 'FREE_TEST');
		const connectMessage = new A2AConnectMessage(userInfo, sessionInfo);
		this.setCurrentConnectMessage(connectMessage);
		this.setEnabled(true);

		if (sessionId.indexOf('.') === -1) {
			a2aRemote.connect(connectMessage,
				(responseMessage) => this.handleRemoteConnectionEvent(responseMessage),
				(msgs) => this.processMessages(msgs),
				(quality) => this.getEventBus().$emit('A2ANetworkQualityStatus', quality),
				(responseMessage) => this.getEventBus().$emit('A2APostResponseMessage', responseMessage),
				cbok, cberr);
		} else {
			this.connectApp2AppLocal(connectMessage, sessionId, 25988, cbok, cberr);
		}
	},
	disconnect() {
		this.setEnabled(false);
		if (a2aRemote.isConnected()) {
			a2aRemote.disconnect();
		}
		if (a2aLocal.isConnected()) {
			a2aLocal.disconnect();
		}
	},
	handleRemoteConnectionEvent(responseMessage) {
		responseMessage.isRemoteConnected = a2aRemote.isConnected();
		responseMessage.isLocalConnected = a2aLocal.isConnected();

		this.getEventBus().$emit('A2AConnectMessage', responseMessage);
	},
	handleLocalConnectionEvent(responseMessage) {
		responseMessage.isRemoteConnected = a2aRemote.isConnected();
		responseMessage.isLocalConnected = a2aLocal.isConnected();

		this.getEventBus().$emit('A2AConnectMessage', responseMessage);

		if (a2aLocal.isConnected()) {
			a2aRemote.stopCheckingMessages();
		} else if (this.isEnabled() === true && a2aRemote.isConnected()) {
			a2aRemote.startCheckingMessages();
		}
	},
	connectApp2AppLocal(connectMessage, host, port, cbok, cberr) {
		a2aLocal.connect(connectMessage, host, port,
			(responseMessage) => this.handleLocalConnectionEvent(responseMessage),
			(msgs) => this.processMessages(msgs),
			(quality) => this.getEventBus().$emit('A2ANetworkQualityStatus', quality),
			(responseMessage) => this.getEventBus().$emit('A2APostResponseMessage', responseMessage),
			cbok, cberr);
	},
	getConnectionStatus() {
		const status = {};
		status.isRemoteConnected = a2aRemote.isConnected();
		status.isLocalConnected = a2aLocal.isConnected();
		return status;
	},
	/// //////////////////////////////////////////////////////////////
	// POST MESSAGE
	postMessage(destinationUserId, messageType, messageContent, userMessageId, cbok, cberr) {
		if (a2aLocal.isConnected()) {
			a2aLocal.postMessage(destinationUserId, messageType,
				messageContent, userMessageId, cbok, cberr);
		} else {
			a2aRemote.postMessage(destinationUserId, messageType,
				messageContent, userMessageId, cbok, cberr);
		}
	},
	/// //////////////////////////////////////////////////////////////
	// RECEIVE MESSAGE
	getMessageQueue() {
		return messageQueue;
	},
	getPendingMessage() {
		return this.getMessageQueue().shift();
	},
	getPendingMessageCount() {
		return this.getMessageQueue().length;
	},
	startCheckingMessages() {
		a2aRemote.startCheckingMessages();
	},
	stopCheckingMessages() {
		a2aRemote.stopCheckingMessages();
	},
	processMessages(messages) {
		if (!messages || !messages.length || messages.length === 0) {
			return;
		}

		for (let i = 0; i < messages.length; i += 1) {
			const mi = new A2AMessageInfo(messages[i]);
			if (!mi.userMessageId || !this.getMessageIdBucket().includes(mi.userMessageId)) {
				this.getMessageIdBucket().push(mi.userMessageId);
				this.getMessageQueue().push(mi);
				if (mi.messageType === 'LOCALENDPOINT') {
					this.connectApp2AppLocal(this.getCurrentConnectMessage(), mi.messageContent, 25988);
				}
			}
		}
		this.getEventBus().$emit('A2AMessageInfo');
	}
};
