import Vue from 'vue';
import electron from './native/electron';
import cordova from './native/cordova';
import web from './native/web';

const mobile = require('is-mobile');

export const Platform = Object.freeze({
	WEB: 'web',
	ELECTRON: 'electron',
	CORDOVA: 'cordova',
	CHROMEBOOK: 'chromebook'
});

export const OS = Object.freeze({
	WINDOWS: 'Windows',
	ANDROID: 'Android',
	MACOS: 'Mac OS',
	LINUX: 'Linux',
	IOS: 'iOS'
});

let api;
const nativeEventBus = new Vue();

export default {
	getPlatform() {
		if (window.electronAPI) {
			return Platform.ELECTRON;
		}
		if (window.cordova) {
			return Platform.CORDOVA;
		}
		return Platform.WEB;
	},
	getOS() {
		const { userAgent } = window.navigator;
		const { platform } = window.navigator;
		const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
		const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
		const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
		let os = null;

		if (macosPlatforms.indexOf(platform) !== -1) {
			os = OS.MACOS;
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			os = OS.IOS;
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			os = OS.WINDOWS;
		} else if (/Android/.test(userAgent)) {
			os = OS.ANDROID;
		} else if (!os && /Linux/.test(platform)) {
			os = OS.LINUX;
		}

		return os;
	},
	isMobile() {
		return mobile({ tablet: true });
	},
	init() {
		if (this.getPlatform() === Platform.ELECTRON) {
			api = electron;
		} else if (this.getPlatform() === Platform.CORDOVA) {
			api = cordova;
		} else {
			api = web;
		}
		api.init(nativeEventBus);
	},
	getEventBus() {
		return nativeEventBus;
	},
	getItem(key) {
		return api.getItem(key);
	},
	setItem(key, value) {
		api.setItem(key, value);
	},
	removeItem(key) {
		api.removeItem(key);
	},
	exitApp() {
		api.exitApp();
	},
	// app2app local
	a2aConnect(host, port) {
		api.a2aConnect(host, port);
	},
	a2aDisconnect() {
		api.a2aDisconnect();
	},
	a2aSendMessage(message) {
		api.a2aSendMessage(message);
	},
	downloadFile(url, options) {
		return api.downloadFile(url, options);
	}
};
