import Vue from 'vue';

let eventBus = null;

/* eslint-disable no-unused-vars */
export default {
	init(nativeEventBus) {
		this.setEventBus(nativeEventBus);

		document.addEventListener('visibilitychange', (event) => {
			this.getEventBus().$emit(document.hidden ? 'window-blur' : 'window-focus');
		}, false);

		Vue.$log.info('Web platform initialized');
	},
	getEventBus() {
		return eventBus;
	},
	setEventBus(bus) {
		eventBus = bus;
	},
	getItem(key) {
		return localStorage.getItem(key);
	},
	setItem(key, value) {
		localStorage.setItem(key, value);
	},
	removeItem(key) {
		localStorage.removeItem(key);
	},
	exitApp() {
		throw new Error('Not implemented');
	},
	// app2app local
	a2aConnect(host, port) {
		throw new Error('Not implemented');
	},
	a2aDisconnect() {
	},
	a2aSendMessage(message) {
	},
};
