import xml2js from 'xml2js';

export default class A2AConnectResponseMessage {
	constructor(xml) {
		this.endpoint = null;
		this.message = null;
		this.rsok = false;
		this.token = null;

		xml2js.parseString(xml, { explicitRoot: false, explicitArray: false }, (err, result) => {
			if (result) {
				this.endpoint = result.ep;
				this.message = result.msg;
				this.rsok = result.rsok === 'true';
				this.token = result.t;
			}
		});
	}
}
