import xml2js from 'xml2js';

export default class A2AUserInfo {
	constructor(userId, commonName, userType, userStatus) {
		this.uid = userId;
		this.ucm = commonName;
		this.ut = userType;
		this.ust = userStatus;

		const builder = new xml2js.Builder({ rootName: 'user' });
		this.serialize = () => {
			const clone = { ...this };
			delete clone.serialize;
			return builder.buildObject(clone);
		};
	}
}
