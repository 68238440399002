import xml2js from 'xml2js';
import a2aUtils from './A2AUtils';

export default class PollingSessionInfo {
	constructor(xml) {
		this.appId = null;
		this.version = null;
		this.supportsAnswerConfirmation = false;

		if (!xml) {
			return;
		}

		xml2js.parseString(a2aUtils.safeDecode(xml),
			{ explicitRoot: false, explicitArray: false }, (err, result) => {
				if (result) {
					this.appId = result['app-id'];
					this.version = result['app-version'];
					this.supportsAnswerConfirmation = result['supports-answer-confirmation'];
				}
			});
	}
}
