export const Mode = Object.freeze({
	NONE: 'NONE',
	ONLINE_TEST: 'ONLINE TEST',
	POLLING_SESSION: 'POLLING SESSION'
});

export const QuestionType = Object.freeze({
	Q_MC_TYPE: '0',	// Multiple choice
	Q_MR_TYPE: '1', // Multiple response
	Q_NR_TYPE: '2', // Numeric response
	Q_SA_TYPE: '3', // Short answer
	Q_TF_TYPE: '5', // True/False
});

export const NetworkQuality = Object.freeze({
	OK_QUALITY: 'ok',
	WARNING_QUALITY: 'warning',
	ERROR_QUALITY: 'error',
});
