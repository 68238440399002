import xml2js from 'xml2js';
import a2aUtils from './A2AUtils';

function cleanCDATA(content) {
	if (content && content.indexOf('<![CDATA[') === 0 && content.length >= 12) {
		return content.substring(9, content.length - 3);
	}

	return content;
}

function getNextImage(pos, content) {
	const imgPos = content.indexOf('<img', pos);

	if (imgPos < 0) {
		return null;
	}

	const srcPos = content.indexOf('src', imgPos);
	const endPos = content.indexOf('>', imgPos);

	if (srcPos >= endPos) {
		return null;
	}

	const qsPos = content.indexOf('"', srcPos);

	if (qsPos < 0 || qsPos > endPos) {
		return null;
	}

	const qePos = content.indexOf('"', qsPos + 1);

	if (qePos < 0 || qePos > endPos) {
		return null;
	}

	return content.substring(qsPos + 1, qePos);
}

function getNextPosImage(pos, content) {
	const imgPos = content.indexOf('<img', pos);

	if (imgPos < 0) {
		return 0;
	}

	const endPos = content.indexOf('>', imgPos);

	return endPos;
}

export default class QuestionDefinition {
	constructor(xml) {
		this.questionId = -1;
		this.questionContent = null;
		this.questionContentType = null;
		this.questionExternalContent = null;
		this.questionExternalContentType = null;
		this.questionName = null;
		this.questionType = null;
		this.questionSubtype = null;
		this.allowMultipleChances = false;
		this.useLetters = false;
		this.forceAnswerPresentation = false;
		this.answers = [];
		this.givenAnswer = null;
		this.urlImages = [];

		if (!xml) {
			return;
		}

		xml2js.parseString(xml, { explicitRoot: false, explicitArray: false }, (err, result) => {
			if (result) {
				this.questionId = result.qid;
				this.questionContent = a2aUtils.safeDecode(result.qc);
				this.questionContentType = result.qct;
				this.questionExternalContent = a2aUtils.safeDecode(result.qec);
				this.questionExternalContentType = result.qect;
				this.questionName = result.qn;
				this.questionType = result.qt;
				this.questionSubtype = result.qst ? result.qst : 0;
				this.givenAnswer = result.ga;
				this.allowMultipleChances = result.amc === 'true';
				this.useLetters = result.ul === 'true';
				this.forceAnswerPresentation = result.fap === 'true';

				if (result.qa) {
					this.answers = Array.isArray(result.qa.ans)
						? result.qa.ans : [result.qa.ans];
				} else {
					this.answers = [];
				}

				for (let p = 0; p < this.answers.length; p += 1) {
					this.answers[p].akey = cleanCDATA(this.answers[p].akey);
				}
			}
		});
	}

	fillEntity(qdef) {
		this.questionId = qdef.qid;
		this.questionContent = a2aUtils.safeDecode(qdef.qc);
		this.questionContentType = qdef.qct;
		this.questionExternalContent = a2aUtils.safeDecode(qdef.qec);
		this.questionExternalContentType = qdef.qect;
		this.questionName = qdef.qn;
		this.questionType = qdef.qt;
		this.questionSubtype = qdef.qst ? qdef.qst : 0;
		this.givenAnswer = qdef.ga;
		this.allowMultipleChances = qdef.amc === 'true';
		this.useLetters = qdef.ul === 'true';
		if (qdef.fap) {
			this.forceAnswerPresentation = qdef.fap === 'true';
		}

		if (qdef.qa) {
			this.answers = Array.isArray(qdef.qa.ans)
				? qdef.qa.ans : [qdef.qa.ans];
		} else {
			this.answers = [];
		}

		for (let p = 0; p < this.answers.length; p += 1) {
			this.answers[p].akey = cleanCDATA(this.answers[p].akey);
		}
	}

	getUrlImages() {
		let pos = 0;
		do {
			const urlImage = getNextImage(pos, this.questionContent);
			if (urlImage !== null && urlImage !== '') {
				this.urlImages.push(`${urlImage}`);
			}
			pos = getNextPosImage(pos, this.questionContent);
		} while (getNextPosImage(pos, this.questionContent) > 0);
	}
}
