import { /* Domain, */ QuestionType } from '@/components/common/constants';
// import QuestionUtil from '@/components/util/QuestionUtil';
import XmlUtil from '@/components/util/XmlUtil';
import a2aUtils from '@/model/A2AUtils';

const { createHash } = require('crypto');

const customerKey = '73c9021b145e41cd91cbd7726902f6f0';

const exports = {};

/* exports.parseHomeworkId = (homeworkId) => {
	const pos = homeworkId.indexOf('#');

	if (pos === -1) {
		window.$store.commit('setDomain', Domain.DOMAIN_PUBLIC);
		// domain = Domain.DOMAIN_PUBLIC;
		return homeworkId;
	}

	const sub = homeworkId.substring(pos + 1);

	if (sub === Domain.DOMAIN_DEV) {
		window.$store.commit('setDomain', Domain.DOMAIN_DEV);
		// domain = Domain.DOMAIN_DEV;
	} else if (sub === Domain.DOMAIN_QA) {
		window.$store.commit('setDomain', Domain.DOMAIN_QA);
		// domain = Domain.DOMAIN_QA;
	} else {
		window.$store.commit('setDomain', Domain.DOMAIN_PUBLIC);
		// domain = Domain.DOMAIN_PUBLIC;
	}

	return homeworkId.substring(0, pos);
}; */

exports.getHomeworkXML = (homeworkId, studentId, deviceId) => {
	const version = 'vPadPC';

	let xmlHomework = '';
	xmlHomework += '<?xml version=\'1.0\' encoding=\'UTF-8\'?>';
	xmlHomework += '<GetHomework xmlns:xsi=\'http://www.w3.org/2001/XMLSchema-instance\' xsi:noNamespaceSchemaLocation=\'/xsd/GetHomework.xsd\'>';
	xmlHomework += `<homeworkId>${homeworkId}</homeworkId><studentId>${studentId}</studentId><deviceId>${deviceId}</deviceId>`;
	xmlHomework += `<deviceInfo><d_type>vPad2</d_type><d_version>${version}</d_version><d_otherInfo></d_otherInfo></deviceInfo>`;
	xmlHomework += '</GetHomework>';

	return xmlHomework;
};

exports.getHomeworkXMLProtected = (homeworkId, studentId, password) => {
	let xmlHomework = '';
	xmlHomework += '<?xml version=\'1.0\' encoding=\'UTF-8\'?>';
	xmlHomework += '<GetHomework xmlns:xsi=\'http://www.w3.org/2001/XMLSchema-instance\' xsi:noNamespaceSchemaLocation=\'/xsd/GetHomework.xsd\'>';
	xmlHomework += `<homeworkId>${homeworkId}</homeworkId><studentId>${studentId}</studentId><password>${password}</password>`;
	xmlHomework += '</GetHomework>';

	return xmlHomework;
};

// eslint-disable-next-line max-len
/*
exports.downloadImages =
(countHomeworkImagesCB, savingHomeworkCB, saveHomeworkOkCB, saveHomeworkFailCB) => {
	let countImagesDownloaded = 0;

	for (let i = 0; i < window.$store.state.test.questions.length; i += 1) {
		const q = window.$store.state.test.questions[i];

		for (let j = 0; j < q.urlImages.length; j += 1) {
			const urlImage = q.urlImages[j];

			// eslint-disable-next-line max-len
			const pathImage = XmlUtil.storeImage(urlImage,
				XmlUtil.getNameImageFromUrl(urlImage), window.$store.state.homeworkId);

			// TODO SOLO PARA PRUEBAS
			if (pathImage === '') { // !== '') {
				// q.questionContent = q.questionContent.replace(urlImage, `file://${pathImage}`);

				countImagesDownloaded += 1;

				if (countHomeworkImagesCB) {
					countHomeworkImagesCB(countImagesDownloaded);
				}

				// TODO Actualizamos welcome con el número de imágenes completadas
				// mHandler.post(mShowProgressDownloadImage);
			}
		}

		window.$store.commit('updateQuestion', q);
		// vPadSessionInfo.currentHomework.vTest.questions.set(i, q);
	}

	// isBusy = false;

	if (savingHomeworkCB) {
		savingHomeworkCB();
	}
	// mHandler.post(mShowStoreMessage);

	if (exports.createPersistenceXML()) {
		// TODO Procesar test online
		if (saveHomeworkOkCB) {
			saveHomeworkOkCB();
		}
		// mHandler.post(mDoProcessHomework);
		return;
	}

	// TODO Mostrar error: No se ha podido guardar el test online
	if (saveHomeworkFailCB) {
		saveHomeworkFailCB();
	}
	// mHandler.post(mDoShowErrorSaveLocalHomework);
};
*/

exports.getCurrentTDefAsXML = () => {
	const storedDate = XmlUtil.getCurrentDateWithFormat();

	let xml = '';
	xml += '<?xml version=\'1.0\' encoding=\'utf-8\' standalone=\'yes\'?>';
	xml += '<GetHomeworkResponse>';
	xml += `<storedDate>${storedDate}</storedDate>`;
	xml += `<studentId>${window.$store.state.studentId}</studentId>`;
	xml += `<domain>${window.$store.state.domain}</domain>`;
	xml += `<homeworkId>${window.$store.state.onlineTestId}</homeworkId><status>OK</status><tdef>`;
	xml += `<qcount>${window.$store.state.currentTDef.questions.length}</qcount>`;
	xml += `<sq>${window.$store.state.currentTDef.shuffleQuestions}</sq>`;
	xml += '<questions>';

	for (let i = 0; i < window.$store.state.currentTDef.questions.length; i += 1) {
		const q = window.$store.state.currentTDef.questions[i];

		xml += `<qdata><qid>${q.questionId}</qid>`;
		xml += `<qn>${a2aUtils.safeEncode(q.questionName)}</qn>`;
		if (q.questionContent === 'about:blank') {
			xml += '<qc></qc>';
		} else {
			xml += `<qc>${a2aUtils.safeEncode(q.questionContent)}</qc>`;
		}
		xml += `<qct>${q.questionContentType}</qct>`;
		xml += `<qec>${a2aUtils.safeEncode(q.questionExternalContent)}</qec>`;
		xml += `<qect>${q.questionExternalContentType}</qect>`;
		xml += `<qt>${q.questionType}</qt>`;
		xml += `<qst>${q.questionSubtype}</qst>`;
		xml += `<ul>${q.useLetters}</ul>`;
		xml += `<amc>${q.allowMultipleChances}</amc>`;
		xml += `<ga>${q.givenAnswer ? q.givenAnswer : ''}</ga>`;
		xml += '<qa>';
		for (let j = 0; j < q.answers.length; j += 1) {
			const a = q.answers[j];

			xml += '<ans>';
			xml += `<aid>${a2aUtils.safeEncode(a.aid)}</aid>`;
			xml += `<akey>${a2aUtils.safeEncode(a.akey)}</akey>`;
			xml += `<at>${a2aUtils.safeEncode(a.at)}</at>`;
			xml += '</ans>';
		}
		xml += '</qa>';
		// xml += `<co>${q.comment}</co>`;
		xml += '</qdata>';
	}
	xml += '</questions>';
	xml += '</tdef>';
	xml += '</GetHomeworkResponse>';

	return xml;
};

exports.setHomeworkXML = (homeworkId, studentId, deviceId) => {
	let xml = '';
	xml += '<?xml version=\'1.0\' encoding=\'UTF-8\'?>';
	xml += '<PostParticipantResponses xmlns:xsi=\'http://www.w3.org/2001/XMLSchema-instance\' xsi:noNamespaceSchemaLocation=\'../xsd/PostParticipantResponses.xsd\'>';
	xml += `<homeworkId>${homeworkId}</homeworkId>`;
	xml += `<studentId>${studentId}</studentId>`;
	xml += `<deviceId>${deviceId}</deviceId>`;
	xml += '<ParticipantResponses>';

	for (let i = 0; i < window.$store.state.currentTDef.questions.length; i += 1) {
		const q = window.$store.state.currentTDef.questions[i];

		xml += '<response>';
		xml += `<r_studentId>${studentId}</r_studentId>`;
		xml += `<r_questionId>${q.questionId}</r_questionId>`;

		if (q.givenAnswer && q.givenAnswer.length > 0) {
			if (q.questionType === QuestionType.Q_TYPE_SHORT_TEXT) {
			/*
			|| QuestionUtil.convertQuestionType(q.questionType) === QuestionType.Q_TYPE_CONSTRUCTIVE) {
			*/
				xml += `<r_response><![CDATA[${q.givenAnswer}]]></r_response>`;
			} else {
				xml += `<r_response>${q.givenAnswer}</r_response>`;
			}
		} else {
			xml += '<r_response></r_response>';
		}

		if (q.comment !== '') {
			xml += `<r_comment><![CDATA[${q.comment}]]></r_comment>`;
		} else {
			xml += '<r_comment></r_comment>';
		}
		xml += '</response>';
	}

	xml += '</ParticipantResponses>';
	xml += '</PostParticipantResponses>';

	return xml;
};

exports.getHmac = (xml) => {
	const key = customerKey + xml;
	return createHash('sha256').update(key).digest('hex');
};

export default exports;
