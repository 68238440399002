import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueGtag from 'vue-gtag';
import VueLogger from 'vuejs-logger';
import VueI18n from 'vue-i18n';
import getBrowserLocale from '@/components/util/get-browser-locale';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import './registerServiceWorker';
import discovery from './discovery';
import router from './router';
import store from './store';
import './app.scss';

library.add(faUserSecret);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(require('vue-shortkey'));

Vue.config.productionTip = false;

// **********************************************************

const isProduction = process.env.NODE_ENV === 'production';

// eslint-disable-next-line func-names
window.setLogLevel = function (level) {
	if (!level || !['debug', 'info', 'warn', 'error', 'fatal'].includes(level)) {
		level = isProduction ? 'warn' : 'debug';
	}

	const options = {
		isEnabled: true,
		logLevel: level,
		stringifyArguments: false,
		showLogLevel: true,
		showMethodName: true,
		separator: '|',
		showConsoleColors: true,
	};

	Vue.use(VueLogger, options);

	Vue.$log.debug(`*** log level set to '${level}'`);
};

// **********************************************************

Vue.use(VueI18n);

function getStartingLocale() {
	const browserLocale = getBrowserLocale({ countryCodeOnly: true });
	const supportedLocales = ['es', 'en'];

	if (supportedLocales.includes(browserLocale)) {
		return browserLocale;
	}

	return process.env.VUE_APP_I18N_LOCALE || 'en';
}

function loadLocaleMessages() {
	const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages = {};
	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});

	return messages;
}

export const i18n = new VueI18n({
	locale: getStartingLocale(),
	fallbackLocale: 'en',
	messages: loadLocaleMessages()
});

// eslint-disable-next-line func-names
window.setLocale = function (locale) {
	if (locale && ['es', 'en'].includes(locale)) {
		i18n.locale = locale;
	}

	Vue.$log.debug(`*** locale set to '${i18n.locale ? i18n.locale : getStartingLocale()}'`);

	document.documentElement.lang = i18n.locale ? i18n.locale : getStartingLocale();
};

// **********************************************************

Vue.use(VueGtag, {
	config: { id: isProduction ? discovery.gtag_id : discovery.gtag_id_dev }
}, router);

// **********************************************************

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');

window.$store = store;
