import xml2js from 'xml2js';

export default class A2ACheckMessage {
	constructor(sessionToken, lastAck, doPing) {
		this.token = sessionToken;
		this.ack = lastAck;

		const builder = new xml2js.Builder({
			rootName: 'app2app',
			renderOpts: {
				pretty: false, indent: '', newline: '', cdata: true
			}
		});
		this.serialize = () => {
			const clone = { ...this };
			delete clone.serialize;
			clone.$ = { id: 1, type: 'check', ping: doPing };

			return builder.buildObject(clone);
		};
	}
}
