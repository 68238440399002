import xml2js from 'xml2js';

export default class A2APostMessage {
	constructor(sessionToken, destinationUserId, messageType, messageContent, userMessageId) {
		A2APostMessage.id = A2APostMessage.id || 1;
		this.token = sessionToken;
		this.duid = destinationUserId;
		this.mt = messageType;
		this.mc = messageContent;
		this.umid = userMessageId;

		A2APostMessage.nextId = () => {
			A2APostMessage.id += 1;
			return A2APostMessage.id;
		};

		const builder = new xml2js.Builder({
			rootName: 'app2app',
			renderOpts: {
				pretty: false, indent: '', newline: '', cdata: true
			}
		});
		this.serialize = () => {
			const clone = { ...this };
			delete clone.serialize;
			clone.$ = { id: A2APostMessage.nextId(), type: 'post' };

			return builder.buildObject(clone);
		};
	}
}
