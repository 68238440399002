import xml2js from 'xml2js';

export default class A2ASessionInfo {
	constructor(sessionId, service, localEndpoint, sname, siinfo, icon) {
		this.siid = sessionId;
		this.sle = localEndpoint;
		this.name = sname;
		this.info = siinfo;
		this.sicon = icon;
		this.service = service;
		this.bookingInfo = '';

		const builder = new xml2js.Builder({ rootName: 'sinfo' });
		this.serialize = () => {
			const clone = { ...this };
			delete clone.serialize;
			return builder.buildObject(clone);
		};
	}
}
